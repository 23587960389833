import { useState, useEffect } from "react"
import axios from "axios"
import { Typography, Form, Input, DatePicker, Select } from "antd"
import dayjs from "dayjs"
const {Text} = Typography
export default function AdditionalFields(props){

    const {setFields, fields, reload, setReload, ticketid} = props
    const [defaultFields, setDefaultFields] = useState([])
    
    useEffect(() => {
        const fetchData = async () => {
            const responseGroups = await axios.post("/fields/display");
            setDefaultFields(responseGroups.data.data)
            const responseValues = await axios.post("/sprawa/get/values",{
                ticketid: ticketid
            });
            setFields(responseValues.data)
        }
        fetchData()
    },[reload])

    const handleChange = (value, name, label, id) => {
        setFields(
            {
                ...fields,
                [name]: {
                    ...fields[name],
                    value: value,
                    name: name,
                    label: label,
                    id: id
                }
            }
        )
    }
    
    return <>
    <div 
    style={{borderRadius: "5px", padding: "10px", border: "1px solid #E2E2E2", width: "100%", display: "flex",flexDirection: "column", gap: "20px"}}
    >
        <Text style={{fontSize: "14px", fontWeight: "600"}}>Dodatkowe pola</Text>
        {defaultFields.map((x)=> {
            return (
                <div style={{padding: "10px", border: "1px solid #E2E2E2", display: 'flex', flexDirection: "column", gap: '10px'}}>
                    <Text style={{fontSize: "13px", fontWeight: "600"}}>{x.groupName}</Text>
                    {x.fields.map((y) => {
                        if(y.type == "Tekst"){
                            return <Form.Item label={y.name}>
                                <Input
                                    value={fields['pole'+y.id]?.value}
                                    onChange={(e) => handleChange(e.target.value, 'pole'+y.id, y.name, y.id)}
                                    name={'pole'+y.id}
                                    placeholder={y.name} 
                                />
                            </Form.Item>   
                        }
                        if(y.type == "Data"){
                            return <Form.Item label={y.name}>
                                <DatePicker 
                                value={fields['pole'+y.id]?.value ? dayjs.unix(fields['pole'+y.id]?.value): null}
                                onChange={(date, dateString) => handleChange(dayjs(date).unix(), 'pole'+y.id, y.name, y.id)}
                                name={'pole'+y.id}
                                style={{width: '100%'}}
                                    size="medium"
                                />
                            </Form.Item>   
                        }
                        if(y.type == "Pole wyboru"){
                            return <Form.Item label={y.name}>
                                <Select
                                    value={fields['pole'+y.id]?.value}
                                    onChange={(e) => handleChange(e, 'pole'+y.id, y.name, y.id)}
                                    name={'pole'+y.id}
                                    options={y.options.map(option => ({value: option, label: option}))}
                                    size="medium"
                                />
                            </Form.Item>   
                        }
                    })}
                </div>
            )
        })}
    </div>
    </>
}