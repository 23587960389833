import React, { useState, useEffect } from 'react';
import { Table, Spin, Flex, Badge, Select, Space, Input, Typography, Button as ButtonS, Tooltip, Modal, Progress, Dropdown, Menu, Checkbox } from 'antd';
import { useNavigate } from 'react-router';
// import Button from '../../../../../components/Button';
import {Button} from 'antd'
import axios from 'axios';
import {
  SearchOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  PushpinOutlined,
  DownOutlined,
  EyeOutlined
} from '@ant-design/icons';
import useDebouncedOnChange from '../../../../../functions/useDebouncedOnChange'
import datetimeParser from '../../../../../functions/datetimeParser';
import CaseStatus from '../../../components/caseStatus';
import SimpleAvatar from '../../../components/simpleAvatar';
import HighlightShort from '../../../../../components/HighlightShort';
import Highlight from '../../../../../components/HighlightShort';
import { UserContext } from '../../../components/privateroute';
import IsFavorite from '../../../components/IsFavorite';
import AdvancedSearching from '../advancedSearching';
import CanWrapper from '../../../../../components/Can';
import UserRaport from '../userRaport';
import saveToLocalStorage from '../../../../../functions/saveToLocalStorage';
import BulkEdit from '../bulkEdit';
const {Text} = Typography

const DataTable = (props) => {
  const {departs, setShowFilters, showFilters} = props
  const globalUser = React.useContext(UserContext);
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null); // State to track selected status filter

  const [user, setUser] = useState(null)
  const [percent, setPercents] = useState(0);
  const [pdfModal, setPdfModal] = useState(false)
  const [values, setValues] = useState(null)
  const [open, setOpen] = useState(false);
  const [lists, setLists] = useState([])
  const [bulkIDs, setBulkIDs] = useState([]);
  const [pagination, setPagination] = useState({
    current: JSON.parse(localStorage.getItem("currentPage")) || 1,
    pageSize: 20,
    list: 'none',
    total: 0,
    search: "",
    advancedSearch: JSON.parse(localStorage.getItem("advancedSearch")) || null,
    orderby: []
  });
  const [reload, setReload] = useState(false)
  const [searchValue, setSearchValue] = useState(''); // State to store input value
  const [loading, setLoading] = useState(false);
  const setSearch = (value) => {
    setPagination({...pagination, search: value, current: 1})
  }
    // Function to handle search action
  const handleSearch = () => {
      setPagination({ ...pagination, search: searchValue, current: 1 }); // Set the search value in pagination
  };
  useEffect(() => {
      setValues(null)
      const fetchData = async () => {
          const response = await axios.post("/sprawa/default/values");
          console.log(response.data)
          setValues(response.data)
      }
      fetchData()
  },[])
  useEffect(() => {
    console.log(pagination.advancedSearch)
  }, [pagination.advancedSearch])
  const debouncedOnChange = useDebouncedOnChange(setSearch);
  const updateSpecialValue = (newValue) => {
    setPagination(prevPagination => {
      // Check if advancedSearch is not null and contains the 'special' object
      const hasSpecial = prevPagination.advancedSearch && prevPagination.advancedSearch.some(item => item.name === 'special');
      
      let newAdvancedSearch;
      if (hasSpecial) {
        // If the 'special' object exists, update its value to newValue
        newAdvancedSearch = prevPagination.advancedSearch.map(item =>
          item.name === 'special' ? { ...item, value: newValue } : item
        );
      } else {
        // If the 'special' object does not exist, add it with value newValue
        // Also handle the case where advancedSearch is initially null
        newAdvancedSearch = [...(prevPagination.advancedSearch || []), { name: 'special', value: newValue }];
      }
      
      // Return the updated pagination object
      return {
        ...prevPagination,
        advancedSearch: newAdvancedSearch
      };
    });
  };
  const updateWatchValue = (newValue) => {
    setPagination(prevPagination => {
      // Check if advancedSearch is not null and contains the 'special' object
      const hasSpecial = prevPagination.advancedSearch && prevPagination.advancedSearch.some(item => item.name === 'favorite');
      
      let newAdvancedSearch;
      if (hasSpecial) {
        // If the 'special' object exists, update its value to newValue
        newAdvancedSearch = prevPagination.advancedSearch.map(item =>
          item.name === 'favorite' ? { ...item, value: newValue } : item
        );
      } else {
        // If the 'special' object does not exist, add it with value newValue
        // Also handle the case where advancedSearch is initially null
        newAdvancedSearch = [...(prevPagination.advancedSearch || []), { name: 'favorite', value: newValue }];
      }
      
      // Return the updated pagination object
      return {
        ...prevPagination,
        advancedSearch: newAdvancedSearch
      };
    });
  };
  
  const fetchData = async (page, pageSize, search, orderby) => {
    try {
      setLoading(true);
      const resperms = await axios.post(process.env.REACT_APP_API_URL+'/perms/get/by/user');
      const userPerms = resperms.data;
      const listy = await axios.post('/sprawy/lists/get');
      const newLists = listy.data.data.map(item => ({
        value: item.swlistid,
        label: item.swlistname
      }));
      setLists([{ value: 'none', label: 'Wszystkie sprawy - brak wybranej listy' }, ...newLists]);
      const response = await axios.post('/sprawy/list/posts', {
        pagination: { ...pagination, current: page, pageSize: pageSize, search: search, orderby},
        departs: departs,
        isadmin: userPerms.includes('can_see_all_tickets')
      });
      setData(response.data.rows);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

    // Menu items for the dropdown
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <CanWrapper perms={["can_add_new_tickets"]}>
            <Button onClick={() => navigate("/sprawy/nowa")}>Nowa sprawa</Button>
          </CanWrapper>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            onClick={() => {
              const specialValue = pagination.advancedSearch?.find(item => item.name === 'special')?.value;
              updateSpecialValue(specialValue === 1 ? 0 : 1);
            }}
            type={pagination.advancedSearch?.find(item => item.name === 'special')?.value === 1 ? 'primary' : 'default'}
            danger={pagination.advancedSearch?.find(item => item.name === 'special')?.value === 1}
          >
            Sprawy specjalne
          </Button>
        </Menu.Item>
        <Menu.Item key="6">
          <Button
            onClick={() => {
              const specialValue = pagination.advancedSearch?.find(item => item.name === 'favorite')?.value;
              updateWatchValue(specialValue === 1 ? 0 : 1);
            }}
            type={pagination.advancedSearch?.find(item => item.name === 'favorite')?.value === 1 ? 'primary' : 'default'}
            danger={pagination.advancedSearch?.find(item => item.name === 'favorite')?.value === 1}
          >
            Obserwowane
          </Button>
        </Menu.Item>
        <Menu.Item key="3">
          <AdvancedSearching setPagination={setPagination} pagination={pagination} />
        </Menu.Item>
        <Menu.Item key="4">
          <CanWrapper perms={["can_generate_raports"]}>
            <Button onClick={() => generateRaport()}>Raport PDF</Button>
          </CanWrapper>
        </Menu.Item>
        <Menu.Item key="5">
          <CanWrapper perms={["can_generate_raports"]}>
            <UserRaport />
          </CanWrapper>
        </Menu.Item>
      </Menu>
    );

    const generateRaport = async () => {
      try {
        if (pagination.total > 100) {
          Modal.confirm({
            title: 'Czy jesteś pewien?',
            content: `Suma spraw dla jakich chcesz wygenerować raport PDF wynosi ${pagination.total} pozycji. Stworzenie raportu dla tak dużej ilości spraw może chwilę potrwać i nie można go anulować. Jesteś pewien, że chcesz go wygenerować?`,
            onOk: async () => {
              await proceedWithRaport(); // Call the actual function to generate the report
            },
            onCancel() {
              console.log('Cancelled report generation');
            },
            okText: 'Wygeneruj'
          });
        } else {
          await proceedWithRaport(); // Call the actual function to generate the report
        }
      } catch (error) {
        console.error('Error in generateRaport:', error);
      }
    };
    
    const proceedWithRaport = async () => {
      try {
        setPdfModal(true);
        setPercents(0);
    
        const totalSeconds = Math.ceil(pagination.total / 100);
        const percentIncrement = 100 / totalSeconds;
    
        const percentInterval = setInterval(() => {
          setPercents((currentPercent) => {
            const updatedPercent = currentPercent + percentIncrement;
            if (updatedPercent >= 100) {
              clearInterval(percentInterval); // Clear interval when reaching 100%
              return 100;
            }
            return Number(updatedPercent.toFixed(2));
          });
        }, 1100); // Update every second
    
        // Send request to generate the report
        const response = await axios.post('/sprawy/list/raport', {
          pagination: pagination,
        });
    
        // Download the generated report file
        axios({
          url: `/files/download/global/${response.data.filename}`, // Replace with your server URL
          method: 'POST',
          responseType: 'blob', // Important
        }).then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          const match = contentDisposition.match(/filename="(.+)"/);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', match ? match[1] : 'report.pdf'); // Use matched filename or a default
          document.body.appendChild(link);
          link.click();
          link.remove();
        }).catch((error) => {
          console.error('Download error:', error);
        });
    
        clearInterval(percentInterval);
        setPercents(100);
        setPdfModal(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, pagination.search, pagination.orderby);
  }, [pagination.current, pagination.pageSize, pagination.search, pagination.advancedSearch, reload, departs, pagination.orderby, pagination.list]);

  // const handleTableChange = (pagination, filters, sorts) => {
  //   saveToLocalStorage('currentPage',pagination.current)
  //   if(sorts.column){
  //     setPagination({
  //       ...pagination,
  //       orderby: [{key: sorts.columnKey, order: sorts.order == 'ascend' ? 'ASC' : 'DESC'}]
  //     });
  //   } else {
  //     setPagination({
  //       ...pagination,
  //       orderby: []
  //     });
  //   }
  // };

  const handleTableChange = (pagination, filters, sorts) => {
    saveToLocalStorage('currentPage',pagination.current)
    if(sorts.column){
      setPagination({
        ...pagination,
        orderby: [{key: sorts.columnKey, order: sorts.order == 'ascend' ? 'ASC' : 'DESC'}]
      });
    } else {
      setPagination({
        ...pagination,
        orderby: []
      });
    }
    
    // Handle filtering logic for status
    if (filters.status && filters.status.length > 0) {
      const selectedStatus = filters.status[0]; // Get the first selected filter value
      setPagination(prev => ({
        ...prev,
        advancedSearch: [
          ...prev.advancedSearch.filter(item => item.name !== 'swtickets.ticketstatusid'), // Remove old filter if it exists
          { name: 'swtickets.ticketstatusid', value: selectedStatus } // Add new filter
        ],
      }));
    } else {
      if(pagination.advancedSearch.length == 0){
        setPagination(prev => ({
          ...prev,
          advancedSearch: [],
        }));
      }
    }
  };
  
  

  const handlePageSizeChange = (value) => {
    setPagination({
      ...pagination,
      pageSize: value,
      current: 1, // Reset to the first page when changing page size
    });
  };

  const columns = [
    // Define your table columns here
    {
      title: (
        <Flex justify='center'>
          <Checkbox 
            checked={bulkIDs.length == data.length} 
            onChange={() => {
              if(bulkIDs.length == data.length){
                setBulkIDs([])
              } else {
                setBulkIDs(data.map(item => item.ticketid))
              }
            }}
          />
        </Flex>
      ),
      dataIndex: 'bulk_edit_checkbox',
      key: 'bulk_edit_checkbox',
      render: (item, record) => (
        <Flex justify='center'>
          <Checkbox 
            checked={bulkIDs.includes(record.ticketid)} 
            onChange={() => {
              setBulkIDs(prevBulkIDs => {
                // Check if ticketid is already in the bulkIDs
                if (prevBulkIDs.includes(record.ticketid)) {
                  // If it's checked, remove it from the array
                  return prevBulkIDs.filter(id => id !== record.ticketid);
                } else {
                  // If it's not checked, add it to the array
                  return [...prevBulkIDs, record.ticketid];
                }
              });
            }}
          />
        </Flex>
      ),
    },
    {
      title: 'ID Sprawy',
      dataIndex: 'ticketid',
      key: 'ticketid',
      render: (item, record) => {
        return <div style={{fontSize: '18px', textAlign: 'right'}} onClick={() => {navigate(`/sprawy/${record.ticketid}`)}}>
          #{record.ticketid}
          </div>
      },
    },
    {
      title: 'Sprawa',
      dataIndex: 'subject',
      key: 'subject',
      render: (item, record) => {
        return <div className={record.watcher_visited == '1' ? 'nonvisited' : ''} style={{display: 'flex', gap: '10px', cursor: "pointer", maxWidth: "1000px", minWidth: "300px"}} onClick={() => {navigate(`/sprawy/${record.ticketid}`)}}>
          {record.ss == '1' ? <div style={{height: "50px", width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ff4d4f', fontSize: '18px', fontWeight: '700', color: 'white', flex: 'none',}}>SS</div> : <></>}
          {record.watcher_visited == '1' ? <div style={{height: "50px", width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#3F51B5', fontSize: '18px', fontWeight: '700', color: 'white', padding: '0 10px'}}>N</div> : <></>}
          <div>
          <Text style={{fontSize:'16px'}}>{record.priorityid == '4' ? <span style={{color: 'red', fontWeight: '700'}}>!PILNE - </span> : null}{record.priorityid == '3' ? <span style={{color: 'red', fontWeight: '700'}}>!WAŻNE - </span> : null}<>{record.is_favorite ? <span style={{height: '20px', width: '20px', display: 'inline-flex', marginRight: '5px', justifyContent: 'center', alignItems: 'center', background: '#B57531'}}><EyeOutlined style={{color: 'white'}}></EyeOutlined></span> : <></>}{item}</></Text>
          <Text style={{fontSize:'12px',color: "rgba(0, 0, 0, 0.60)", display: "block"}}>{datetimeParser(record.dateline, 'YYYY-MM-DD HH:mm:ss')}</Text>
          {pagination.search == '' ? <></> : <HighlightShort textContent={record.contents} searchString={pagination.search}/>}
          </div>
          </div>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: values?.ticketstatusid.map(item => ({
        text: item.label,
        value: item.value,
      })),
      // Use onFilter to filter records based on the selected status
      onFilter: (value, record) => {
        setSelectedStatusFilter(value); // Set the selected status to ensure only one checkbox is checked
        return true;
      },
      filteredValue: selectedStatusFilter ? [selectedStatusFilter] : [], // Ensure only one checkbox is checked
      render: (item, record) => {
        return <CaseStatus record={record} />;
      },
    },
    {
      title: 'Ostatnia zmiana',
      dataIndex: 'lastactivity',
      key: 'lastactivity',
      sorter: true,
      render: (item, record) => {
      return <div class="min-200">{datetimeParser(record.lastactivity, 'YYYY-MM-DD HH:mm:ss')}<div>{record?.userDetailsLast?.mlg_users_fullname}</div></div>
      },
    },
    {
      title: 'Data zdarzenia',
      dataIndex: 'accident_dateline',
      key: 'accident_dateline',
      sorter: true,
      render: (item, record) => {
        return <div class="min-200">{datetimeParser(record.accident_dateline, 'YYYY-MM-DD')}</div>
      },
    },
    {
      title: 'Zgłaszający',
      dataIndex: 'phone',
      key: 'phone',
      render: (item, record) => {
        return <SimpleAvatar user={record.userDetails}></SimpleAvatar>
      }
    },
    {
      title: 'Przypisany',
      dataIndex: 'przypisany',
      key: 'przypisany',
      render: (item, record) => {
        return <SimpleAvatar user={record.userDetailsPrzypisany}></SimpleAvatar>
      }
    },
  ];
  useEffect(() => {
    console.log(pagination)
  }, [pagination])
  return (
    <>
    <div>
    <Spin spinning={loading}>
    <Select 
        placeholder="Wybierz listę" 
        value={pagination.list}
        style={{
          width: 'calc(100% - 40px)',
          margin: '10px 20px 0px 20px'
        }}
        onChange={(e) => {
          setPagination({
            ...pagination,
            list: e,
            current: 1, // Reset to the first page when changing page size
          });
        }}
      >
        {lists.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      </Spin>
    <div className='smallResCol' size="large" style={{display: 'flex', width:'100%', gap: "10px", padding: "10px 20px 0px 20px"}}>
      <BulkEdit reload={reload} setReload={setReload} ids={bulkIDs}></BulkEdit>
      <div style={{ display: 'flex', width: '100%' }}>
      <Input
        value={searchValue} // Bind the input value to state
        onChange={(e) => setSearchValue(e.target.value)} // Update state on input change
        onPressEnter={handleSearch} // Trigger search on Enter key press
        disabled={loading} // Disable input if loading
        addonBefore={<SearchOutlined onClick={handleSearch} />} // Trigger search on icon click
        placeholder="Wyszukaj sprawę | #ID, @tag, Tytuł, treść, województwo, powiat, gmina"
      />
      <Button onClick={handleSearch} disabled={loading}>Wyszukaj</Button> {/* Trigger search on button click */}
    </div>
      <div className='hideMobile' style={{display: 'flex', width:'100%', gap: "10px"}}>
      <CanWrapper perms={["can_add_new_tickets"]}><Button style={{}} onClick={() => navigate("/sprawy/nowa")}>Nowa sprawa</Button></CanWrapper>
      <Button style={{}} onClick={() => {
        pagination.advancedSearch?.find(item => item.name === 'special')?.value == 1 ? updateSpecialValue(0) : updateSpecialValue(1)
      }} type={pagination.advancedSearch?.find(item => item.name === 'special')?.value == 1 ? 'primary' : 'default'} danger={pagination.advancedSearch?.find(item => item.name === 'special')?.value == 1}>Sprawy specjalne</Button>
      <Button style={{}} onClick={() => {
        pagination.advancedSearch?.find(item => item.name === 'favorite')?.value == 1 ? updateWatchValue(0) : updateWatchValue(1)
      }} type={pagination.advancedSearch?.find(item => item.name === 'favorite')?.value == 1 ? 'primary' : 'default'} danger={pagination.advancedSearch?.find(item => item.name === 'favorite')?.value == 1}>Obserwowane</Button>
      <AdvancedSearching setPagination={setPagination} pagination={pagination}></AdvancedSearching>
      <CanWrapper perms={["can_generate_raports"]}><Button style={{}} onClick={() => generateRaport()}>Raport PDF</Button></CanWrapper>
      <CanWrapper perms={["can_generate_raports"]}><UserRaport></UserRaport></CanWrapper>
      </div>
      <Modal
        centered={true}
        open={pdfModal}
        title="Generowanie raportu PDF"
        onOk={() => {}}
        onCancel={() => {}}
        footer={[]}
      >
        <Progress percent={percent} type="line" />
      </Modal>
      <Dropdown className='showMobile' overlay={menu} trigger={["click"]} onOpenChange={(flag) => setOpen(flag)} open={open}>
        <Button>
          Akcje i filtry <DownOutlined />
        </Button>
      </Dropdown>
      {/* <ButtonS icon={<FilterOutlined />} className='shopOn800px' style={{width: "40px"}} onClick={() => setShowFilters(!showFilters)}></ButtonS> */}
    </div>
    <div className='overflowTable' style={{padding: "0px 20px"}}>
    <Spin spinning={loading}>
      <Table
      size="small"
      style={{width:'100%', gap: "10px", marginTop: '20px'}}
        columns={columns}
        dataSource={data}
        pagination={{
          ...pagination,
          position: ["topRight", "bottomRight"],
          showSizeChanger: true,
          pageSizeOptions: ['20', '40', '50'],
          onShowSizeChange: handlePageSizeChange,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} elementów`, // Customize total display
          locale: {
            items_per_page: '/ stronę', // Customize the text in the select dropdown
          },
        }}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Brak danych' }}
        onRow={(record) => ({
          style: {
            opacity: record.statusopacity ? Math.min(Math.max(record.statusopacity, 0), 1) : 1,
            background: record.statusopacity != 1 ? '#80808085' : '',
          },
        })}
      />
    </Spin>
    </div>
    </div>
    </>
  );
};

export default DataTable;