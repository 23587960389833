import axios from "axios";
import Cookies from "js-cookie";

let cachedPermissions = null;
let lastFetchedTime = null;
const PERMISSIONS_REFRESH_INTERVAL = 10 * 60 * 1000; // 10 minutes in milliseconds

// Function to fetch permissions from API
const fetchPermissions = async () => {
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + '/perms/get/by/user', {}, {
            headers: {
                "Authorization": Cookies.get('mgltoken'),
                "App": process.env.REACT_APP_APP_KEY
            }
        });
        cachedPermissions = res.data;
        lastFetchedTime = new Date().getTime();
    } catch (error) {
        cachedPermissions = null;
        lastFetchedTime = null;
    }
};

// Function to check if permissions are cached and valid
const isCacheExpired = () => {
    if (!lastFetchedTime) return true;  // No cached data
    const currentTime = new Date().getTime();
    return (currentTime - lastFetchedTime) > PERMISSIONS_REFRESH_INTERVAL;
};

// Function to check permissions
const isCan = async (perms) => {
    // If cache is expired or doesn't exist, fetch new permissions
    if (!cachedPermissions || isCacheExpired()) {
        await fetchPermissions();
    }

    // If still no permissions after fetching, return null
    if (!cachedPermissions) return null;

    // Check if all requested permissions are included in the cached permissions
    const allIncluded = perms.every(perm => cachedPermissions.includes(perm));
    return allIncluded ? true : null;
};

// Call this when the app first loads (e.g., in a useEffect in the main app component)
fetchPermissions();  // Prefetch permissions on app load

export default isCan;