import { Button } from "antd"
import { useNavigate } from "react-router"
import CanWrapper from "../../../../../components/Can";
export default function SmallTicket(props) {
    const {ticket, unplugTicket} = props
    const navigate = useNavigate();
    return <div style={{
        border:"1px solid #F0F0F0",
        borderRadius: '5px',
        background: '#F5F5F5',
        padding: '10px',
        boxShadow: '0px 3px 1.6px 0px rgba(184, 184, 184, 0.25)'
    }}>
        <h4 style={{
            margin: '0px',
            width: '100%',
            textWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}>#{ticket.ticketid} {ticket.subject}</h4>
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'end', marginTop: '10px'}}>
            <CanWrapper perms={['can_connect_tickets']}><Button onClick={() => unplugTicket(ticket)}>Rozłącz</Button></CanWrapper>
            <Button onClick={() => navigate('/sprawy/'+ticket.ticketid)}>Przejdź</Button>
        </div>
    </div> 
}