import React, { useEffect, useState } from 'react';
import { Badge, Drawer, Typography, Button, notification, Spin, Tabs } from "antd"
import axios from 'axios';
import { MenuOutlined, AlertOutlined } from '@ant-design/icons';
import { UserContext } from '../privateroute';
import datetimeParser from '../../../../functions/datetimeParser';
import { useNavigate } from 'react-router';
import Noti from './addons/noti';
import socketIOClient from "socket.io-client";
const { Title, Text} = Typography;
export default function NotificationBox() {
  
    const globalUser = React.useContext(UserContext);
    const navigate = useNavigate()
    const [notifications, setNotifications] = useState(null)
    const [manies, setManies] = useState(0)
    const [reload, setReload] = useState(false)
    const [showed, setShowed] = useState(false)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [curentType, setCurrentType] = useState(0)
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };

    const items = [
      {
        key: '0',
        label: 'Wszystko',
      },
      {
        key: '1',
        label: 'Użytkownika',
      },
      {
        key: '2',
        label: 'Systemowe',
      },
    ];
    const onChange = (key) => {
      setCurrentType(key)
    };

    useEffect(() => {
        const socket = socketIOClient();
    
        socket.on('reloadNotificationAfterAddNew', () => {
          setReload(new Date().getTime())
          notification.info({
            message: "Masz nowe powiadomienia",
            duration: 3
          });
        });
    
        return () => socket.disconnect();
      }, []);

      const morePage = () => {
        setPage(parseInt(page) + 1)
      }

    useEffect(() => {
        setLoading(true)
        const fetchNotifications = async () => {
            if(globalUser != undefined && globalUser.user != undefined){
                const response = await axios.post('/notifications/list/by/user', {
                    userid: globalUser.user.mlg_users_id,
                    page: page,
                    type: curentType
                });   
                setNotifications(response.data.rows)
                setManies(response.data.manies)
                setLoading(false)
            }
        }
        fetchNotifications()
    },[globalUser, reload, page, curentType])

    const setStatus = (status) => {
        switch(status) {
            case 1:
              return '#0075E1'
              break;
            case 2:
              return '#E1B000'
              break;
            case 3:
              return '#E10000'
              break;
            default:
            return '#0075E1'
        }
    }

    return <>
        <Badge onClick={showDrawer} count={manies} offset={[0, 5]} style={{ cursor: "pointer" }}>
            <AlertOutlined style={{ fontSize: '25px', cursor: "pointer" }}/>
        </Badge>
      <Drawer title="Powiadomienia" placement="right" onClose={onClose} open={open}>
        <Tabs style={{marginBottom: '10px'}} defaultActiveKey={curentType} items={items} onChange={onChange} />
        {notifications != null ? notifications.map((x) => {
            return <Noti globalUser={globalUser} onClose={onClose} x={x}></Noti>
        }) : <></>}
        <div style={{padding: '5px', display: loading? 'flex' : 'none', justifyContent: 'center', width: '100%'}}>
        <Spin spinning={loading}/>
        </div>
        {(parseInt(manies) - (page * 10)) > 0 ? <Button style={{width: '100%'}} onClick={() => morePage()}>ZOBACZ WIĘCEJ {parseInt(manies) - (page * 10)}</Button> : <></>}
      </Drawer>
    </>

}