import { useEffect, useState } from "react";
import columns from "./Columns";
import axios from "axios";
import { Table, Switch, Spin } from "antd";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import useDebouncedOnChange from "../../../../../functions/useDebouncedOnChange";
import { Button, Modal, notification } from "antd";
export default function TagsTable() {
  const [initialData, setInitialData] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
    search: '',
    total: 0, // You'll need to manage total records for accurate pagination controls
  });

  useEffect(() => { 
    setLoading(true);
    axios.post("/tags/display/fields", {
      limit: pagination.limit,
      page: pagination.page,
      search: pagination.search
    }).then(response => {
      // Assuming response.data contains the data and total number of records
      const { data, total } = response.data;
      setInitialData(data);
      setPagination(p => ({ ...p, total }));
      setLoading(false);
    })
  }, [reload, pagination.page, pagination.limit, pagination.search]);

  // Handle table change event, specifically pagination changes
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(p => ({
      ...p,
      page: pagination.current,
      limit: pagination.pageSize,
    }));
  };

const handleSearch = (value) => {
    setPagination((p) => ({ ...p, search: value }));
}

const debounce = useDebouncedOnChange(handleSearch, 300);

return (
    <Spin spinning={loading}>
        <div style={{ display: 'flex', gap: '10px' }}>
            <Input
                prefix={<SearchOutlined />}
                value={pagination.search}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: '20px' }} // Add margin bottom of 10px
            />
        <Button onClick={() => setModalOpen(true)}>Dodaj nowy tag</Button>
        <Modal
            title="Dodaj nowy tag"
            visible={modalOpen}
            onCancel={() => setModalOpen(false)}
            footer={[
                <Button onClick={() => setModalOpen(false)}>Anuluj</Button>,
                <Button onClick={() => {
                    axios.post('/tag/add/new', { tagname: newTag })
                        .then(response => {
                            notification.success({
                                message: response.data.message,
                            });
                            setModalOpen(false);
                            setReload(prev => !prev);
                        })
                        .catch(error => {
                            notification.error({
                                message: error.response.data.message,
                            });
                        });
                }}>Dodaj</Button>
            ]}
        >
            <Input placeholder="Nazwa tagu" onChange={(e) => setNewTag(e.target.value)}/>
        </Modal>
        </div>
        <Table
            columns={columns({ setInitialData, initialData })}
            dataSource={initialData}
            pagination={{
                current: pagination.page,
                pageSize: pagination.limit,
                total: pagination.total,
            }}
            onChange={handleTableChange}
            rowKey="tagid"
        />
    </Spin>
);
}
