import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Form, Tree, Space, Card, Typography, Select, Avatar } from 'antd';
const {Text, Title} = Typography
const UrlPreview = (props) => {
    const {url} = props
    const [preview, setPreview] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post('/api/url/preview',{
                url: url
            });
            setPreview(response.data)
        }
        fetchData()
    },[url])
    if(preview){
        if(preview.url == url){
            if(preview == null){
                return <></>
            }
            return (
                <a target='_blank' href={preview.url} style={{width: "fit-content", display: "block"}}><div style={{
                    display: "flex",
                    borderRadius: "5px",
                    overflow: "hidden",
                    background: "white",
                    boxShadow: "1px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                    maxWidth: "1000px",
                    marginTop: "10px",
                    textDecoration: "none"
                    }}>
                    <div>
                        <img style={{width:"150px", minHeight: "150px", height: "100%", objectFit: "cover"}} src={preview.image}></img>
                    </div>
                    <div style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}>
                        <div>
                        <Title style={{margin: "0px"}} level={5}>{preview.title}</Title>
                        <Text style={{margin: "0px", fontSize: "12px"}}>{preview.description}</Text>
                    </div>
                    <div>
                        <a target="_blank" href={preview.url}>{preview.url}</a>
                    </div>
                    </div>
                </div></a>
            );
        }
    }
};
export default UrlPreview;