import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

const TextEditor = ({ value, onChange, placeholder, clear, setClear }) => {
  const [editorHtml, setEditorHtml] = useState(value || '');
  const handleChange = (html) => {    
    // Configure DOMPurify to allow only specific tags
    const cleanConfig = {
      ALLOWED_TAGS: ['b', 'strong', 'u', 's', 'strike', 'i', 'em', 'p' , 'br'],
    };
    const cleanedHtml = DOMPurify.sanitize(html, cleanConfig);
    setEditorHtml(cleanedHtml);
    if (onChange) {
      onChange(html);
    }
  };

  useEffect(() => {
    if (clear) {
      setEditorHtml('');
      setClear(false);
    }
  }, [clear]);

  return (
    <ReactQuill
      theme="snow"
      onChange={handleChange}
      value={editorHtml}
      modules={TextEditor.modules}
      formats={TextEditor.formats}
      bounds={'.app'}
      placeholder={placeholder}
    />
  );
};

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

TextEditor.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

TextEditor.formats = [
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'indent',
  'link',
];

export default TextEditor;
