import React, { useState, useEffect } from 'react';
import { Table, Spin, Flex, Badge, Select, Space, Input, Typography, Button, Tooltip, notification } from 'antd';
import { useNavigate } from 'react-router';
// import Button from '../../../../../components/Button';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import {
  SearchOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import useDebouncedOnChange from '../../../../../functions/useDebouncedOnChange'
import datetimeParser from '../../../../../functions/datetimeParser';
import CaseStatus from '../../../components/caseStatus';
import SimpleAvatar from '../../../components/simpleAvatar';
import HighlightShort from '../../../../../components/HighlightShort';
import Highlight from '../../../../../components/HighlightShort';
import { UserContext } from '../../../components/privateroute';
import IsFavorite from '../../../components/IsFavorite';
import ConnectedTickets from '../connectedTickets';
import NoteModal from '../noteModal';
import EditNote from '../editNote';
import FilePreview from '../../../components/filePreview';
const {Text} = Typography

const DataTable = (props) => {
  const {departs, setShowFilters, showFilters} = props
  const globalUser = React.useContext(UserContext);
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    search: "",
    advancedSearch: null
  });
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);
  const setSearch = (value) => {
    setPagination({...pagination, search: value})
  }
  const debouncedOnChange = useDebouncedOnChange(setSearch);

  const fetchData = async (page, pageSize, search) => {
    try {
      setLoading(true);
      // Replace 'yourApiEndpoint' with your actual server API endpoint
      const response = await axios.post('/notatki/list/notes', {
        pagination: { ...pagination, current: page, pageSize: pageSize, search: search },
        departs: departs
      });

      setData(response.data.rows);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const removeNote = async (id) => {
    try {
      const response = await axios.post('/notatki/remove', {
        id: id
      });
      setReload(!reload)
      notification.success({
          message: "Notatka usunięta.",
          duration: 3
      });
    } catch (error) {
      notification.error({
          message: "Wystąpił błąd. Spróbuj ponownie.",
          duration: 3
      });
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, pagination.search);
  }, [pagination.current, pagination.pageSize, pagination.search, pagination.advancedSearch, reload, departs]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handlePageSizeChange = (value) => {
    setPagination({
      ...pagination,
      pageSize: value,
      current: 1, // Reset to the first page when changing page size
    });
  };

  const columns = [
    {
      title: 'Treść notatki',
      dataIndex: 'content',
      key: 'content',
      render: (item, record) => {
        return <div style={{cursor: "pointer"}}>
          <EditNote content={record.content} reload={reload} setReload={setReload} id={record.id}>
          <Text style={{fontSize:'16px', display: "block"}}><div dangerouslySetInnerHTML={{ __html: item }} /></Text>
          <Text style={{fontSize:'12px',color: "rgba(0, 0, 0, 0.60)", display: "block"}}>{datetimeParser(record.createdat, 'YYYY-MM-DD HH:mm:ss')}</Text>
          </EditNote>
          <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
              {record.files ? record.files.map((x) => {
                  return <FilePreview data={x}></FilePreview>
              }) : <></>}
          </div>
          <div style={{display: 'flex', gap: '20px', marginTop: '20px', justifyContent: 'flex-end'}}>
          {record.ticketid != 0 && record.postid != 0 ? <Button success onClick={() => navigate(`/sprawy/${record.ticketid}/${record.postid}`)}>Dodano do sprawy #{record.ticketid} w wpisie #{record.postid}</Button> : <></>}
          {record.ticketid == 0 && record.postid == 0 ?
          <Dropdown 
          overlay={
            <Menu>
              <Menu.Item key="1">
                <div onClick={() => {
                  navigate('/sprawy/nowa', { state: { content: record.content, date: record.createdat, noteid: record.id } })
                }}>Utwórz jako sprawę</div>
              </Menu.Item>
              <Menu.Item key="2">
                <ConnectedTickets content={record.content} noteid={record.id}></ConnectedTickets>
              </Menu.Item>
              <Menu.Item key="3">
                <div onClick={() => {removeNote(record.id)}} style={{color: "red"}}>Usuń</div>
              </Menu.Item>
            </Menu>
          } 
          trigger={['click']}
          >
            <Button>
              Akcje
              <DownOutlined />
            </Button>
          </Dropdown>
           : <></>}
          </div>
          </div>
      },
    },
  ];


  return (
    <>
    <div>
    <div className='smallResCol' size="large" style={{display: 'flex', width:'100%', gap: "10px", padding: "10px 20px 0px 20px"}}>
      <Input onChange={debouncedOnChange} addonBefore={<SearchOutlined />} placeholder="Wyszukaj sprawę | #ID, Tytuł, treść" />
      <NoteModal reload={reload} setReload={setReload}></NoteModal>
    </div>
    <div className='overflowTable' style={{padding: "0px 20px"}}>
    <Spin spinning={loading}>
      <Table
      size="small"
      style={{width:'100%', gap: "10px", marginTop: '15px'}}
        columns={columns}
        dataSource={data}
        pagination={{
          ...pagination,
          position: ["bottomRight"],
          showSizeChanger: true,
          pageSizeOptions: ['20', '40', '50'],
          onShowSizeChange: handlePageSizeChange,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} elementów`, // Customize total display
          locale: {
            items_per_page: '/ stronę', // Customize the text in the select dropdown
          },
        }}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Brak danych' }}
      />
    </Spin>
    </div>
    </div>
    </>
  );
};

export default DataTable;