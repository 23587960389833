import { Modal, Button, Select, Input, notification } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";

export default function BulkAddToList(props) {
  const { tickets, setReload, reload } = props;
  const [open, setOpen] = useState(false);
  const [lists, setLists] = useState([{ value: "new", label: "Utwórz nową listę" }]); // New default option
  const [selectedList, setSelectedList] = useState(null);
  const [newListName, setNewListName] = useState("");
  const [inputVisible, setInputVisible] = useState(false); // To control the input field visibility

  useEffect(() => {
    if (open) {
      const fetchLists = async () => {
        try {
          const response = await axios.post("/sprawy/lists/get");
          const newLists = response.data.data.map((item) => ({
            value: item.swlistid,
            label: item.swlistname,
          }));
          setLists([{ value: "new", label: "Utwórz nową listę" }, ...newLists]);
        } catch (error) {
          console.error("Error fetching lists:", error);
        }
      };
      fetchLists();
    }
  }, [open]);

  const handleListChange = (value) => {
    setSelectedList(value);
    if (value === "new") {
      setInputVisible(true); // Show input for a new list
    } else {
      setInputVisible(false); // Hide input when any other list is selected
      console.log("Selected List:", value);
      console.log("Selected Tickets:", tickets);
    }
  };

  const handleSave = async () => {
    if (selectedList === "new" && newListName.trim()) {
        const response = await axios.post("/sprawy/lists/add/new",{
            tickets: tickets,
            name: newListName
        });
      notification.success({
        message: response.data.message,
        duration: 3,
      });
      setOpen(false);
      setSelectedList(null);
      setReload(!reload)
    } else if (selectedList !== "new") {
        const response = await axios.post("/sprawy/lists/add/exist",{
            tickets: tickets,
            list: selectedList
        });
      notification.success({
        message: response.data.message,
        duration: 3,
      });
      setOpen(false);
      setSelectedList(null);
      setReload(!reload)
    } else {
      notification.error({
        message: "Błąd",
        description: "Musisz wprowadzić nazwę nowej listy!",
        duration: 3,
      });
    }
    setNewListName("");
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Dodaj do listy</Button>
      <Modal
        centered={true}
        open={open}
        okText="Zapisz"
        title="Dodaj do swojej listy"
        onOk={handleSave}
        onCancel={() => setOpen(false)}
      >
        <Select
          placeholder="Wybierz listę"
          value={selectedList}
          style={{
            width: "100%",
            marginBottom: inputVisible ? "10px" : "0", // Space for the input if visible
          }}
          onChange={handleListChange}
        >
          {lists.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>

        {inputVisible && (
          <Input
            placeholder="Wprowadź nazwę nowej listy"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            required
          />
        )}
      </Modal>
    </>
  );
}
