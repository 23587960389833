import React, { createContext, useState, useContext, useEffect } from 'react';
import loadFromLocalStorage from '../../../../functions/loadFromLocalStorage';
import { UserContext } from '../privateroute';
import axios from 'axios';
// Creating the context
const TabsGlobalContext = createContext();

export const useTabsGlobal = () => useContext(TabsGlobalContext);

export const TabsGlobalProvider = ({ children }) => {
  const [globalTab, setGlobalTab] = useState(loadFromLocalStorage("tabs") ? loadFromLocalStorage("tabs") : []);
  useEffect(() => {
    const fetchData = async () => {
      const user = await axios.post(process.env.REACT_APP_API_URL+'/auth/get/user');    
      const response = await axios.post('/sprawy/get/favorite', {
        userid: user.data.user.mlg_users_id
      });
      const updatedTabs = [
        ...globalTab.map((existingElement) => {
          return {
            ...existingElement,
            favorite: false, // Initialize the 'favorite' flag for existing elements
          };
        }),
        ...response.data.rows.map((newElement) => {
          const isRepeating = globalTab.some(
            (existingElement) => existingElement.id === newElement.ticketid
          );
          return {
            id: newElement.ticketid,
            title: newElement.subject,
            favorite: true, // Set 'favorite' flag based on repetition
            // Add other properties as needed
          };
        }),
      ];
      const sortedTabs = [...updatedTabs].sort((a, b) => {
        return a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1;
      });
      const uniqueTabs = sortedTabs.filter((element, index, array) => {
        // Check if the current element is the first occurrence of its ID in the array
        return array.findIndex((el) => el.id === element.id) === index;
      });
      setGlobalTab(uniqueTabs)
    }
    fetchData()
  }, [])
  return (
    <TabsGlobalContext.Provider value={{ globalTab, setGlobalTab }}>
      {children}
    </TabsGlobalContext.Provider>
  );
};
