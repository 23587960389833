import React, { useState, useEffect } from 'react';
import { Checkbox, notification } from 'antd';
import axios from 'axios';
const IsFavorite = (props) => {
  const [switchValue, setSwitchValue] = useState(props.value);
    const ticketid = props.ticketid
    const userid = props.userid
  // Effect to synchronize internal state with incoming props
  useEffect(() => {
    setSwitchValue(props.value);
  }, [props.value]); // Dependency array includes props.value

  const handleSwitchChange = async (checked) => {
    const response = await axios.post('/sprawy/change/favorite', {
        userid: userid,
        checked: checked.target.checked,
        ticketid: ticketid,
    });   
    notification.success({
        message: response.data.message,
        duration: 3
    });
    setSwitchValue(checked.target.checked);
  };

  return (
      <Checkbox onChange={handleSwitchChange} checked={switchValue == 1 ? true : false} />
  );
};

export default IsFavorite;
