import { Typography, Button, Modal, notification } from "antd"
import React, { useEffect, useState } from "react"
import axios from "axios"
import TextEditor from "../../../components/textEditor"
import { UserContext } from "../../../components/privateroute"
export default function EditNote({ reload, setReload, id, children, content }){
    const [value, setValue] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const globalUser = React.useContext(UserContext)
    useEffect(() => {
        setValue(content)
    }, [content])
    const onOk = async () => {
        try {
            const response = await axios.post("/notatki/edit",{
                id: id,
                content: value
            });
            notification.success({
                message: "Notatka zaktualizowana.",
                duration: 3
            });
            setReload(!reload)
            setModalOpen(false)
            setValue(null)
        } catch (error) {
            notification.error({
                message: "Błąd serwera.",
                duration: 3
            });
        }
    }
    return <>
        <div style={{}} onClick={() => setModalOpen(true)}>{children}</div>
        <Modal 
            title="Edytuj notatkę"
            open={modalOpen} 
            width={1000}
            onOk={onOk} 
            onCancel={() => setModalOpen(false)} 
            centered
            >
        <TextEditor value={value} onChange={(e) => setValue(e)}></TextEditor>
        </Modal>
    </>
}