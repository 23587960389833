import React, {useEffect, useState} from "react"
import { Typography, Button, Modal, notification } from "antd"
import axios from "axios"
import DataTableModal from "./dataTableModal"
import { UserContext } from "../../../components/privateroute"
import { useNavigate } from "react-router"
const {Text} = Typography
export default function ConnectedTickets(props) {
    const {noteid, content} = props
    const [value, setValue] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const onCancel = () => {
        setModalOpen(false)
    }
    const navigate = useNavigate()
    const globalUser = React.useContext(UserContext)
    const onOk = async () => {
        setConfirmLoading(true)
        const response = await axios.post("/sprawa/new/post",{
            credentials: {
                ticketid: value.ticketid,
                dateline: Math.floor(new Date().getTime() / 1000),
                userid: globalUser.user.mlg_users_id,
                fullname: globalUser.user.mlg_users_fullname,
                email: globalUser.contacts.find(contact => contact.type === 'E-mail')?.value,
                subject: value.subject,
                contents: content, // Initialize contents property
            },
            id: value.ticketid
        });
        if(response.status == 200){
            const updateNote = await axios.post('/notatki/update/after/post', {
                id: noteid,
                ticketid: value.ticketid,
                postid: response.data.id
            });
            // const response = await axios.post('/notatki/remove', {
            //     id: noteid
            // });
            notification.success({
                message: response.data.message,
                duration: 3
            });
            navigate(`/sprawy/${value.ticketid}`)
        } else {
            notification.error({
                message: response.data.message,
                duration: 3
            });
        }
        setConfirmLoading(false)
        setModalOpen(false)
    }
    return <>
            <div onClick={() => setModalOpen(true)}>Utwórz jako wpis</div>
            <Modal 
                title="Wybierz sprawę"
                open={modalOpen} 
                width={1000}
                onOk={onOk} 
                onCancel={onCancel} 
                centered
                confirmLoading={confirmLoading}
                >
                <DataTableModal value={value} setValue={setValue}></DataTableModal>
            </Modal>
    </>
}