import React, {useContext, useEffect, useState} from "react"
import { Typography, Button, Modal, notification } from "antd"
import axios from "axios"
import DataTableModal from "./dataTableModal"
import { UserContext } from "../../../components/privateroute"
import SmallTicket from "./smallTicket"
import CanWrapper from "../../../../../components/Can"
const {Text} = Typography
export default function ConnectedTickets(props) {
    const {credentials, reload, setReload} = props
    const [value, setValue] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const globalUser = useContext(UserContext)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const onCancel = () => {
        setModalOpen(false)
    }
    const unplugTicket = async (x) => {
        const response = await axios.post("/sprawa/unplug/ticket",{
            parentticketid: credentials.ticketid,
            ticketid: x.ticketid,
            userid: globalUser.user.mlg_users_id,
            id: x.id
        });
        if(response.status == 200){
            notification.success({
                message: response.data.message,
                duration: 3
            });
        } else {
            notification.error({
                message: response.data.message,
                duration: 3
            });
        }
        setReload(!reload)
    }
    const onOk = async () => {
        setConfirmLoading(true)
        const response = await axios.post("/sprawa/connect/ticket",{
            userid: globalUser.user.mlg_users_id,
            ticketid: credentials.ticketid,
            parentticketid: value
        });
        if(response.status == 200){
            notification.success({
                message: response.data.message,
                duration: 3
            });
        } else {
            notification.error({
                message: response.data.message,
                duration: 3
            });
        }
        setReload(!reload)
        setConfirmLoading(false)
        setModalOpen(false)
    }
    return <>
        <div 
        style={{borderRadius: "5px", padding: "10px", border: "1px solid #E2E2E2", width: "100%", display: "flex",flexDirection: "column", gap: "20px"}}
        >
            <Text style={{fontSize: "14px", fontWeight: "600"}}>Przypisane sprawy</Text>
            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                {credentials.connectedTickets ? credentials.connectedTickets.map((x) => {
                return <SmallTicket unplugTicket={unplugTicket} ticket={x}></SmallTicket>
                }) : <></>}
            </div>
            <CanWrapper perms={['can_connect_tickets']}><Button onClick={() => setModalOpen(true)}>Połącz nową sprawe</Button></CanWrapper>
            <Modal 
                title="Wybierz sprawę"
                open={modalOpen} 
                width={1000}
                onOk={onOk} 
                onCancel={onCancel} 
                centered
                confirmLoading={confirmLoading}
                >
                <DataTableModal value={value} setValue={setValue} credentials={credentials}></DataTableModal>
            </Modal>
        </div>
    </>
}