import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Tree, Space, Card, Typography, Select, Avatar, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AlertOutlined, DeleteOutlined, CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import datetimeParser from '../../../../../functions/datetimeParser';
import DOMPurify from 'dompurify';
import UrlPreview from '../../../components/urlPreview';
import FilePreview from '../../../components/filePreview';

const { Text, Title } = Typography;
const { confirm } = Modal; // Import Modal confirm

const ReminderPost = (props) => {
  const { data, showLogs, reload, setReload } = props;
  const [removed, setRemoved] = useState(false);
  const datetime = Math.floor(new Date().getTime() / 1000);

  // Funkcja usunięcia przypomnienia
  const removeReminder = async (id, ticketid) => {
    const responseDelete = await axios.post('/sprawa/remove/reminder', {
      id: id,
      ticketid: ticketid,
    });
    setRemoved(true);
  };

  // Funkcja wywołująca modal potwierdzenia
  const showConfirm = (id, ticketid) => {
    confirm({
      title: 'Czy na pewno chcesz usunąć przypomnienie?',
      content: 'To działanie jest nieodwracalne.',
      okText: 'Tak',
      okType: 'danger',
      cancelText: 'Nie',
      onOk() {
        removeReminder(id, ticketid); // Usunięcie przypomnienia po potwierdzeniu
      },
    });
  };

  return (
    <>
      <div
        className='post'
        style={{
          display: (showLogs || datetime <= data.startdateline) && !removed ? 'grid' : 'none',
          gridTemplateColumns: '46px auto',
          columnGap: '20px',
          padding: '20px 0px',
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <div className='post row first'>
          <Avatar
            style={{ backgroundColor: 'black', textTransform: 'uppercase', borderRadius: '5px' }}
            shape='square'
            size={46}
          >
            {data.userDetails
              ? data.userDetails.mlg_users_fullname[0] + data.userDetails.mlg_users_fullname[1]
              : '??'}
          </Avatar>
        </div>
        <div className='post row second'>
          <div>
            <Title style={{ margin: '0px' }} level={5}>
              {data.userDetails ? data.userDetails.mlg_users_fullname : ''}
            </Title>
            <Text style={{ margin: '0px', color: '#9B9B9B' }}>
              {datetimeParser(data.created_at, 'YYYY-MM-DD HH:mm:ss')}
            </Text>
          </div>
          <div style={{ marginTop: '20px' }}></div>
          <span style={{ display: 'block', marginBottom: '10px' }}>
            {data.userDetails.mlg_users_fullname} utworzył/a przypomnienie
          </span>
          <div
            style={{
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
              maxWidth: '500px',
              display: 'grid',
              overflow: 'hidden',
              gridTemplateColumns: '380px 120px',
            }}
          >
            <div style={{ padding: '20px', background: 'white' }}>
              <div
                style={{
                  color: 'black',
                  fontWeight: '700',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                {data.eventname}
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div
                  style={{
                    padding: '5px 10px',
                    background: '#FAFAFA',
                    borderRadius: '5px',
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <CalendarOutlined style={{ color: 'black', fontSize: '18px' }}></CalendarOutlined>
                  <div style={{ fontSize: '16px', fontWeight: '500' }}>
                    {datetimeParser(data.startdateline, 'YYYY-MM-DD')}
                  </div>
                </div>
                <div
                  style={{
                    padding: '5px 10px',
                    background: '#FAFAFA',
                    borderRadius: '5px',
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <ClockCircleOutlined style={{ color: 'black', fontSize: '18px' }}></ClockCircleOutlined>
                  <div style={{ fontSize: '16px', fontWeight: '500' }}>
                    {datetimeParser(data.startdateline, 'HH:mm')}
                  </div>
                </div>
              </div>
              <div
                style={{
                  color: '#BD8136',
                  cursor: 'pointer',
                  marginTop: '10px',
                  padding: '5px 0',
                }}
                className='reminder-btn'
                onClick={() => showConfirm(data.sweventsid, data.ticketid)} // Używamy funkcji z modalem
              >
                Usuń przypomnienie
              </div>
            </div>
            <div
              style={{
                padding: '20px',
                background: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              <CalendarOutlined style={{ color: 'white', fontSize: '35px' }}></CalendarOutlined>
              <div style={{ color: 'white', fontWeight: '700' }}>
                {datetimeParser(data.created_at, 'YYYY-MM-DD')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReminderPost;
