import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, Select, Breadcrumb, message } from 'antd';

const { Option } = Select;

const DodatkowePola = () => {
  const [statusy, setStatusy] = useState([]);

  const [groups, setGroups] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
      const fetchData = async () => {
          const response = await axios.post("/sprawa/default/values",{
              departmentid: null
          });
          setStatusy(response.data.ticketstatusid)

          const responseGroups = await axios.post("/fields/display");
          setGroups(responseGroups.data.data)
      }
      fetchData()
  },[reload])

  const addGroup = () => {
    setGroups([...groups, { groupid: 'new',groupName: '', statusRequired: '', fields: [] }]);
  };

  const removeGroup = async (groupIndex, id) => {
    const response = await axios.post("/fields/delete",{
        groupid: id
    });
    const newGroups = [...groups];
    newGroups.splice(groupIndex, 1);
    setGroups(newGroups);
  };

  const addField = (groupIndex) => {
    const newGroups = [...groups];
    newGroups[groupIndex].fields.push({ name: '', type: '', options: [] });
    setGroups(newGroups);
  };

  const removeField = (groupIndex, fieldIndex) => {
    const newGroups = [...groups];
    if (newGroups[groupIndex].fields.length > 1) {
      newGroups[groupIndex].fields.splice(fieldIndex, 1);
      setGroups(newGroups);
    } else {
      message.error('Grupa musi posiadać przynajmniej jedno pole.');
    }
  };

  const addOptionToField = (groupIndex, fieldIndex) => {
    const newGroups = [...groups];
    newGroups[groupIndex].fields[fieldIndex].options.push('');
    setGroups(newGroups);
  };

  const removeOptionFromField = (groupIndex, fieldIndex, optionIndex) => {
    const newGroups = [...groups];
    newGroups[groupIndex].fields[fieldIndex].options.splice(optionIndex, 1);
    setGroups(newGroups);
  };

  const handleGroupNameChange = (value, groupIndex) => {
    const newGroups = [...groups];
    newGroups[groupIndex].groupName = value;
    setGroups(newGroups);
  };

  const handleFieldChange = (value, groupIndex, fieldIndex, key, optionIndex = null) => {
    const newGroups = [...groups];
    if(fieldIndex < 0){
      newGroups[groupIndex][key] = value;
    } else {
      if (key === 'options' && optionIndex !== null) {
        newGroups[groupIndex].fields[fieldIndex].options[optionIndex] = value;
      } else {
        newGroups[groupIndex].fields[fieldIndex][key] = value;
      }
    }
    setGroups(newGroups);
  };

  const saveGroup = async (groupIndex) => {
    const group = groups[groupIndex];
    if (!group.groupName.trim()) {
      message.error('Nazwa grupy nie może być pusta.');
      return;
    }
    if (group.fields.length === 0) {
      message.error('Grupa musi posiadać przynajmniej jedno pole.');
      return;
    }
    for (const field of group.fields) {
      if (!field.name.trim()) {
        message.error('Nazwy pól nie mogą być puste.');
        return;
      }
      if (field.type === 'Pole wyboru' && field.options.length === 0) {
        message.error('Pole jako select musi posiadać opcje.');
        return;
      }
      for (const option of field.options) {
        if (!option.trim()) {
          message.error('Opcje nie mogą być puste.');
          return;
        }
      }
    }
    const response = await axios.post("/fields/save", {
      group: group
    })
    setReload(!reload)
    
  };

  return (
    <>
      <Breadcrumb style={{ padding: '20px' }}>
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Ustawienia</Breadcrumb.Item>
        <Breadcrumb.Item>Dodatkowe pola</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", background: "white" }}>
        {statusy != null && groups.map((group, groupIndex) => (
          <div key={groupIndex} style={{ marginBottom: "20px", boxShadow: '0px 0px 4px 1px #EFEFEF', padding: '20px' }}>
            <Form layout="vertical">
              <Form.Item label="Nazwa grupy pól">
                <Input value={group.groupName} onChange={(e) => handleGroupNameChange(e.target.value, groupIndex)} placeholder="Nazwa grupy pól" />
              </Form.Item>
              <Form.Item label="Grupa wymagana dla statusu">
                <Select options={statusy} value={group.statusRequired} onChange={(value) => handleFieldChange(value, groupIndex, -1, 'statusRequired')} placeholder="Wybierz status">
                </Select>
              </Form.Item>
              <div>
              {group.fields.map((field, fieldIndex) => (
                <div key={fieldIndex} style={{marginBottom: '20px', padding: '20px', background: '#F2F2F2'}}>
                  <Form.Item label="Nazwa pola">
                    <Input value={field.name} onChange={(e) => handleFieldChange(e.target.value, groupIndex, fieldIndex, 'name')} placeholder="Nazwa pola" />
                  </Form.Item>
                  <Form.Item label="Rodzaj pola">
                    <Select value={field.type} onChange={(value) => handleFieldChange(value, groupIndex, fieldIndex, 'type')} placeholder="Wybierz rodzaj pola">
                      <Option value="Tekst">Tekst</Option>
                      <Option value="Pole wyboru">Pole wyboru</Option>
                      <Option value="Data">Data</Option>
                    </Select>
                  </Form.Item>
                  {field.type === 'Pole wyboru' && field.options.map((option, optionIndex) => (
                    <div style={{padding: '20px', background: 'white', marginBottom: '20px'}}>
                    <Form.Item key={optionIndex} label={`Opcja ${optionIndex + 1}`}>
                      <Input value={option} onChange={(e) => handleFieldChange(e.target.value, groupIndex, fieldIndex, 'options', optionIndex)} placeholder="Wpisz opcję" />
                      <Button onClick={() => removeOptionFromField(groupIndex, fieldIndex, optionIndex)} style={{ marginTop: '10px' }}>Usuń opcję</Button>
                    </Form.Item>
                    </div>
                  ))}
                  {field.type === 'Pole wyboru' && (
                    <Button onClick={() => addOptionToField(groupIndex, fieldIndex)}>Dodaj opcję</Button>
                  )}
                  <Button onClick={() => removeField(groupIndex, fieldIndex)} style={{ marginTop: '10px' }}>Usuń pole</Button>
                </div>
              ))}
              </div>
              <Button onClick={() => addField(groupIndex)} style={{ marginTop: '10px' }}>Dodaj pole</Button>
            </Form>
            <Button onClick={() => saveGroup(groupIndex)} type="primary" style={{ marginTop: '10px' }}>Zapisz grupę</Button>
            <Button onClick={() => removeGroup(groupIndex, group.groupid)} style={{ marginLeft: '10px', marginTop: '10px' }}>Usuń grupę</Button>
          </div>
        ))}
        <Button onClick={addGroup} style={{ marginTop: '20px' }}>Dodaj nową grupę</Button>
      </div>
    </>
  );
};

export default DodatkowePola;
