import React from 'react';
import { Tabs } from 'antd';
import { useTabsGlobal } from '../../routes/views/components/TabsGlobalProvider';
import { useNavigate } from 'react-router';
import { UserContext } from '../../routes/views/components/privateroute';
import { CloseOutlined, PlusOutlined, PushpinOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import saveToLocalStorage from '../../functions/saveToLocalStorage';
import axios from 'axios';
import CanWrapper from '../Can';

const FastEnters = () => {
  const { globalTab, setGlobalTab } = useTabsGlobal();
  const { ticketid } = useParams();
  const globalUser = React.useContext(UserContext)
  const navigate = useNavigate()
  const handleTabClose = async (id, event, favorite) => {
    event.stopPropagation(); // Prevent event from propagating to parent elements
    if(id == ticketid){
      navigate("/sprawy/")
    }
    if(favorite = true){
      const response = await axios.post('/sprawy/change/favorite', {
          userid: globalUser.user.mlg_users_id,
          checked: false,
          ticketid: id,
      });   
    }
    saveToLocalStorage('tabs', globalTab.filter(item => item.id !== id))
    setGlobalTab(globalTab.filter(item => item.id !== id));
  };

  const handleTabClick = (id) => {
    navigate("/sprawy/" + id);
  };
  return (
    <Tabs className='fastEnters' activeKey={ticketid ? ticketid : "0"}>
      {globalTab.map(tab => (
        <Tabs.TabPane
          tab={
            <div style={{ display: "flex", gap: "10px", height: "100%", padding: "10px 0" }} onClick={() => handleTabClick(tab.id)}>
              {tab.favorite ? <PushpinOutlined /> : <></>}
              <span className='elipses'>{tab.title}</span>
              <CloseOutlined onClick={(event) => handleTabClose(tab.id, event, tab.favorite)} />
            </div>
          }
          key={tab.id}
        />
      ))}
      <Tabs.TabPane tab={<CanWrapper perms={["can_add_new_tickets"]}><div onClick={() => handleTabClick('nowa')}><PlusOutlined /></div></CanWrapper>} key={"nowa"}></Tabs.TabPane>
    </Tabs>
  );
};

export default FastEnters;
