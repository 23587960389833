import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import axios from 'axios'
import Cookies from 'js-cookie'
import {Tooltip} from 'antd'
import { AlertOutlined } from '@ant-design/icons'
import { Tag } from 'antd'

export default function CaseStatus(props) {
    const {record} = props
    return (
        <>
        <div style={{display: 'flex', alignContent: 'center'}}>
        <Tag style={{backgroundColor: (record.priorityid == '4' || record.priorityid == '3') ? "rgb(255 0 0 / 14%)" : "rgb(0 0 0 / 4%)", borderColor: "black",color: "black", padding: "5px 10px", width: '150px', textAlign: "center", textTransform: "uppercase", fontWeight: "600"}}>{record.title}</Tag>
        {record.notificationsexist == '1' ? 
        <Tooltip placement="topLeft" title='Sprawa posiada zbliżające się powiadomienia'>
            <AlertOutlined style={{ fontSize: '25px', color: 'red', cursor: 'pointer'}}/>
        </Tooltip>
        : <></>}
        </div>
        </>
    )
}
