import React, {useState, useEffect} from 'react';
import { Tabs } from 'antd';
import axios from 'axios';
import SingleGroup from '../SingleGroup';
const { TabPane } = Tabs;

export default function SettingsTabs(){
    const [settingsFields, setSettingsFields] = useState(null)
    useEffect(() => {
        const fetchSettings = async () => {
            const response = await axios.post(`/settings/display/fields`)
            setSettingsFields(response.data)
        }
        fetchSettings()
    }, [])
    return <>
      <Tabs>
        {settingsFields ? settingsFields.map((tab, index) => (
        <TabPane style={{marginTop: '10px'}} tab={tab.section_name} key={tab.section_id}>
            <div style={{
                  display: "grid",
                  gridGap: "10px",
                  gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
            }}>    
                <SingleGroup data={tab}></SingleGroup>
            </div>
        </TabPane>
        )) : <></>}
    </Tabs>
    </>
}