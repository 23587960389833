import React, { useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

let cachedPermissions = null;
let lastFetchedTime = null;
const PERMISSIONS_REFRESH_INTERVAL = 10 * 60 * 1000; // 10 minutes in milliseconds

// Function to fetch permissions from API
const fetchPermissions = async () => {
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + '/perms/get/by/user', {}, {
            headers: {
                "Authorization": Cookies.get('mgltoken'),
                "App": process.env.REACT_APP_APP_KEY
            }
        });
        cachedPermissions = res.data;
        lastFetchedTime = new Date().getTime();
    } catch (error) {
        cachedPermissions = null;
        lastFetchedTime = null;
        console.error('Error fetching permissions:', error);
    }
};

// Function to check if cache is expired
const isCacheExpired = () => {
    if (!lastFetchedTime) return true;
    const currentTime = new Date().getTime();
    return (currentTime - lastFetchedTime) > PERMISSIONS_REFRESH_INTERVAL;
};

// Higher-Order Component for permission checks
const CanWrapper = ({ children, perms }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        const checkPermissions = async () => {
            // Fetch permissions if cache is expired or doesn't exist
            if (!cachedPermissions || isCacheExpired()) {
                await fetchPermissions();
            }

            // If permissions are still unavailable, stop further processing
            if (!cachedPermissions) {
                setHasPermission(false);
                setIsLoading(false);
                return;
            }

            // Check if all requested permissions are included in cached permissions
            const allIncluded = perms.every(perm => cachedPermissions.includes(perm));
            setHasPermission(allIncluded);
            setIsLoading(false);
        };

        checkPermissions();

        // Optional: Set interval to refresh permissions every 10 minutes
        const interval = setInterval(() => {
            fetchPermissions();
        }, PERMISSIONS_REFRESH_INTERVAL);

        // Clear interval on unmount
        return () => clearInterval(interval);
    }, [perms]);

    if (isLoading) {
        return null; // Or any other loading component
    }

    // Render children only if permission is granted
    return hasPermission ? children : null;
};

export default CanWrapper;
