import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Button} from 'antd';
import {Form, Tree, Space, Card, Typography, Select, Avatar, Image, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {AlertOutlined, DeleteOutlined} from '@ant-design/icons';
import datetimeParser from '../../../../../functions/datetimeParser';
import DOMPurify from 'dompurify';
import UrlPreview from '../../../components/urlPreview';
import FilePreview from '../../../components/filePreview';
import ThumbUp from '../../../../../assets/images/thumbs-up.svg'
import ThumbDown from '../../../../../assets/images/thumbs-down.svg'
import ThumbUpWhite from '../../../../../assets/images/thumbs-up-white.svg'
import ThumbDownWhite from '../../../../../assets/images/thumbs-down-white.svg'
import Map from '../../../../../assets/images/map.svg'
import Phone from '../../../../../assets/images/phone.svg'
import FileText from '../../../../../assets/images/file-text.svg'
const {Text, Title} = Typography

const StructurePost = (props) => {
    const {ticketid, userid, data, showLogs, reload, setReload, specialPeriods} = props
    const [loading, setLoading] = useState(false)
    const changeUp = async () => {
        setLoading(true)
        const response = await axios.post('/sprawa/structure/change',{
            userid: userid,
            idstructure: data.idstructure,
            opinion: 2,
            text: data.text,
            type: data.type,
            ticketid: ticketid
        }) 
        setLoading(false)
        setReload(new Date().getTime())
    }
    const changeDown = async () => {
        setLoading(true)
        const response = await axios.post('/sprawa/structure/change',{
            userid: userid,
            idstructure: data.idstructure,
            opinion: 1,
            text: data.text,
            type: data.type,
            ticketid: ticketid
        }) 
        setLoading(false)
        setReload(new Date().getTime())
    }
    const changeReset = async () => {
        setLoading(true)
        const response = await axios.post('/sprawa/structure/change',{
            userid: userid,
            idstructure: data.idstructure,
            opinion: 0,
            text: data.text,
            type: data.type,
            ticketid: ticketid
        }) 
        setLoading(false)
        setReload(new Date().getTime())
    }
    function isDatelineInRange(objects, dateline) {
        if(objects.length > 0){
            for (let obj of objects) {
              // Check if dateline_end is null
              if (obj.dateline_end === null) {
                // Check if the given dateline is greater than dateline_start
                if (dateline > obj.dateline_start) {
                  return true;
                }
              } else {
                // Check if the given dateline is between dateline_start and dateline_end
                if (dateline >= obj.dateline_start && dateline <= obj.dateline_end) {
                  return true;
                }
              }
            }
            // If none of the objects match the criteria, return false
            return false;
        } else {
            return false
        }
    }
    return (
        <>
        <div className='post' style={isDatelineInRange(specialPeriods, data.dateline) ? {display: "grid", gridTemplateColumns: "46px auto", columnGap: "20px",padding: "20px 0px", borderBottom: "1px solid #D3D3D3", background: 'white', paddingLeft: '15px', borderLeft: '13px solid red', marginLeft: '-20px',} : {display: "grid", gridTemplateColumns: "46px auto", columnGap: "20px",padding: "20px 0px", borderBottom: "1px solid #D3D3D3",} }>
            <div className='post row first'>
                <Avatar style={{backgroundColor: "black", textTransform: "uppercase", borderRadius: "5px"}} shape="square" size={46}>{data.userDetails ? data.userDetails.mlg_users_fullname[0] + data.userDetails.mlg_users_fullname[1]: "??"}</Avatar>
            </div>
            <div className='post row second'>
                <div>
                <Title style={{margin: "0px"}} level={5}>{data.userDetails ? data.userDetails.mlg_users_fullname: ""}</Title>
                <Text style={{margin: "0px", color: "#9B9B9B"}}>{datetimeParser(data.dateline, 'YYYY-MM-DD HH:mm:ss')}</Text>
                </div>
                <div style={{marginTop: "20px"}}>
                </div>
                <span style={{display: 'block', marginBottom: '10px'}}>{data.userDetails.mlg_users_fullname} utworzył/a nową informację</span>
                {/* <div style={{
                    display: "flex",
                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                    background: 'white',
                    borderRadius: '4px',
                    width: 'fit-content'
                }}>
                    <div style={{padding: "10px 20px", maxWidth: '400px', minWidth: 'min(300px)', background: (data.opinion == 0 ? '#ffb6002e' : data.opinion == 1 ? '#ff00002e' : '#00ff522e')}}>
                        <h3 style={{margin: '0px'}}>{data.type == 1 ? 'Adres' : ''}{data.type == 2 ? 'Numer telefonu' : ''}{data.type == 3 ? 'Notatka' : ''}</h3>
                        <p>{data.text}</p>
                        <p>{data.note != 'undefined' && data.note ? (data.type == 1 || data.type == 2 ? data.note : '') : ''}</p>
                        {data.type == 1 ? <>
                        <a target='_blank' src={data.link} onClick={() => window.open(data.link, '_blank')}>Pokaż trasę</a>
                        </> : <></>}
                    </div>
                    
                    <div style={{
                        background: 'rgba(189, 129, 54, 0.20)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '15px',
                        flexDirection: 'row',
                        padding: '10px'
                    }}>
                        <Spin spinning={loading}>
                    <div onClick={() => data.opinion == 1 ? changeReset() : changeDown()}><Image style={{padding: '10px', background: 'white', borderRadius: '30px', cursor: 'pointer', opacity: (data.opinion == 1 ? '1' : '0.2')}} preview={false} src={ThumbDown}></Image></div>
                    </Spin>
                    <Spin spinning={loading}>
                    <div onClick={() => data.opinion == 2 ? changeReset() : changeUp()}><Image style={{padding: '10px', background: 'white', borderRadius: '30px', cursor: 'pointer', opacity: (data.opinion == 2 ? '1' : '0.2')}} preview={false} src={ThumbUp}></Image></div>
                    </Spin>
                    </div>
                </div> */}
                <Spin spinning={loading}>
                <div className='datastructure-post' style={{
                    overflow: 'hidden',
                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                    background: 'white',
                    borderRadius: '4px',
                    width: 'fit-content',
                    opacity: (data.opinion == 2 ? '0.5' : '1')
                }}>
                    <div style={{padding: "10px 20px", maxWidth: '400px', minWidth: 'min(300px)'}}>
                        <h3 style={{margin: '0px', fontSize: '12px', fontWeight: '400'}}>{data.type == 1 ? 'Adres:' : ''}{data.type == 2 ? 'Numer telefonu:' : ''}{data.type == 3 ? 'Notatka:' : ''}</h3>
                        <p style={{margin: 0, fontSize: (data.type == 3 ? '16px' : '22px'), fontWeight: '600'}}>{data.type == 2 ? <a href={"tel:"+data.text}>{data.text}</a> : data.text}</p>
                        <p  style={{margin: 0, fontSize: '14px', fontWeight: '400'}}>{data.note != 'undefined' && data.note ? (data.type == 1 || data.type == 2 ? data.note : '') : ''}</p>
                        {data.type == 1 && data.link != '' ? <>
                        <a target='_blank' src={data.link} onClick={() => window.open(data.link, '_blank')}>Wyznacz trasę</a>
                        </> : <></>}
                        <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                        <Button onClick={() => data.opinion == 1 ? changeReset() : changeDown()} style={{display: 'flex', gap: '10px', alignItems: 'center', background: (data.opinion == 1 ? '#00BB54' : 'white'), color: (data.opinion == 1 ? 'white' : 'black')}}>
                            <Image preview={false} src={data.opinion == 1 ? ThumbUpWhite : ThumbUp} style={{width: '20px', height: '20px', display: 'flex'}}></Image>
                            <span>{data.opinion == 1 ? 'Potwierdzone' : 'Potwierdź'}</span>
                        </Button>
                        <Button onClick={() => data.opinion == 2 ? changeReset() : changeUp()} style={{display: 'flex', gap: '10px', alignItems: 'center', background: (data.opinion == 2 ? '#B40000' : 'white'), color: (data.opinion == 2 ? 'white' : 'black')}}>
                            <Image preview={false} src={data.opinion == 2 ? ThumbDownWhite : ThumbDown} style={{width: '20px', height: '20px', display: 'flex'}}></Image>
                            <span>{data.opinion == 2 ? 'Odrzucone' : 'Odrzuć'}</span>
                        </Button>
                        </div>
                    </div>
                    
                    <div style={{
                        background: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '15px',
                        flexDirection: 'row',
                        padding: '10px'
                    }}>
                        {data.type == 1 ? <Image preview={false} src={Map}></Image> : ''}{data.type == 2 ? <Image preview={false} src={Phone}></Image> : ''}{data.type == 3 ? <Image preview={false} src={FileText}></Image> : ''}
                    </div>
                </div>
                </Spin>
            </div>
        </div></>
    );
};
export default StructurePost;