const perms = {
    "/sprawy": {
      permissions: ["can_list_tickets"]
    },
    "/sprawy/nowa": {
      permissions: ["can_add_new_tickets"]
    },
    "/kalendarz": {
      permissions: ["can_base_edit", "can_base_add"]
    },
    "/notatnik": {
      permissions: ["can_access_notes"]
    },
    "/ustawienia": {
      permissions: ["can_change_settings"]
    }
}
export default perms