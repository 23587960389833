import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Form, Tree, Space, Card, Typography, Select, Avatar, Input, Button, DatePicker } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import datetimeParser from '../../../../../functions/datetimeParser';
import { UserContext } from '../../../components/privateroute';
import TextEditor from '../../../components/textEditor';
import dayjs from 'dayjs';
const {Text, Title} = Typography

const TitlePost = (props) => {
    const {subject, credentials, setCredentials, globalUser, filterOption, getValues, setValues, values, handleDataChange} = props
    const {data} = props

    const handleDateChange = (date, dateString) => {
        const newTimestamp = dayjs(date).unix();
        setCredentials({ ...credentials, accident_dateline: newTimestamp });
    };  

    return (
        <div className={'post newticket'} style={{padding: "20px",width: "100%"}}>
            <div style={{display: "grid", gridTemplateColumns: "46px auto", columnGap: "20px"}}>
                <div className='post row first'>
                    <Avatar style={{backgroundColor: "black", borderRadius: "5px", textTransform: "uppercase"}} shape="square" size={46}>{globalUser.user.mlg_users_fullname[0]}{globalUser.user.mlg_users_fullname[1]}</Avatar>
                </div>
                <div className='post row second' style={{display: "flex", gap: "20px", alignItems: "center"}}>
                    <Title style={{margin: "0px"}} level={4}>{credentials.subject}</Title>
                </div>
            </div>
            <div className='newform'>
            <Form style={{marginTop: "20px"}}>
                <Form.Item
                    label="Tytuł zgłoszenia"
                    rules={[{ required: true, message: 'Zgłoszenie musi mieć swój tytuł'}]}
                >
                    <Input 
                        defaultValue={credentials.subject} 
                        value={credentials.subject} 
                        size="large"
                        name="subject"
                        onChange={(e) => setCredentials({
                            ...credentials,
                            subject: e.target.value
                        })} 
                    />
                </Form.Item>
                <div className='row3'>
                    <Form.Item
                        name="accident_dateline"
                        label="Data zdarzenia"
                    >
                        <DatePicker
                            size="large"
                            defaultValue={dayjs.unix(credentials.accident_dateline)} 
                            onChange={handleDateChange}
                            disabledDate={current => current && current > dayjs().endOf('day')}
                        />
                    </Form.Item>
                    <Form.Item label='Kody rejestracyjne powiatu' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"registerplates"}
                            onChange={(e) => handleDataChange(e, "registerplates")} 
                            options={getValues("registerplates")}
                            value={credentials["registerplates"]}
                            defaultValue={credentials["registerplates"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    {/* <Form.Item
                        name="waitingroom"
                        label="Poczekalnia"
                    >
                        <Select
                        name="waitingroom"
                        onChange={(e) => handleDataChange(e, "waitingroom")} 
                        value={credentials.waitingroom}
                        defaultValue={0}
                        size="large"
                        options={[
                            {
                            value: 0,
                            label: 'Nie',
                            },
                            {
                            value: 1,
                            label: 'Tak',
                            },
                        ]}
                        />
                    </Form.Item> */}
                </div>
                <TextEditor value={credentials.newcomment} onChange={(e) => {setCredentials({...credentials, newcomment: e})}}></TextEditor>
            </Form>
            </div>
        </div>
    );
};
export default TitlePost;