import { Typography } from "antd"
import { Form, Input, Button, notification } from 'antd';
import axios from "axios";
const {Text} = Typography

function convertSettingsToInitialValues(settings) {
    return settings.reduce((acc, setting) => {
      acc[setting.name] = setting.value;
      return acc;
    }, {});
}

export default function SingleGroup(props){
    const {data} = props
    const onFinish = async (values) => {
        try {
            const response = await axios.post("/settings/save/fields",{
                values: values
            });
            notification.success({
                message: "Poprawnie zapisano wartości ustawień.",
                duration: 3
            });
        } catch (error) {
            notification.error({
                message: 'Błąd: '+error,
                duration: 3
            });
        }
    };
    return <>
    {data.groups.map((x) => {
        return <div style={{borderRadius: "5px", padding: "10px", border: "1px solid #E2E2E2", width: "100%", display: "flex",flexDirection: "column", gap: "20px"}}>
            <Text style={{fontSize: "14px", fontWeight: "600"}}>{x.group_name}</Text>
            <Form
            initialValues={convertSettingsToInitialValues(x.settings)}
            layout="vertical"
            name={`group${x.group_id}`}
            onFinish={onFinish}
            autoComplete="off"
            >
            {x.settings.map((field) => {
            return <Form.Item
                label={field.label}
                name={field.name}
                rules={[{ required: true, message: 'Pole nie może być puste!' }]}
            >
                <Input value={field.value} />
            </Form.Item>
            })}
            <Form.Item>
                <Button htmlType="submit">
                Zapisz
                </Button>
            </Form.Item>
            </Form>
        </div>
    })}
    </>

}