import { Route, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin, Flex } from 'antd';
import Cookies from 'js-cookie';
import isCan from '../../../../components/isCan';
import perms from '../../../../config/perms';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
export const UserContext = React.createContext();
export const PrivateRoute = ({ children, mainpath }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate()
  const [isPermitted, setIsPermitted] = useState(false);
  const location = useLocation();
  const isLoginPage = location.pathname.includes('/login');
  const isAccessDeneidPage = location.pathname.includes('/access/deneid');
  const [globalUser, setGlobalUser] = useState({})
  const [lastUrl, setLastUrl] = useState(location.pathname)

  // console.log(Cookies.get('lasturl'))
  useState(() => {
    setLastUrl(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    const fetchData = async () => {
      const permitted = await isCan(perms[mainpath].permissions)
      if((permitted ? false : true) && isAuthenticated){
        navigate("/access/denied")
      }
    }
    fetchData()
  },[isAuthenticated, mainpath])
  // useEffect(() => {
  //   // Add a request interceptor
  //   const requestInterceptor = axios.interceptors.request.use(
  //     (config) => {
  //       // Modify config or add headers before the request is sent
  //       config.headers['Authorization'] = Cookies.get('mgltoken');
  //       config.headers['App'] = process.env.REACT_APP_APP_KEY;
  //       return config;
  //     },
  //     (error) => {
  //       // Do something with request error
  //       return Promise.reject(error);
  //     }
  //   );

  //   // Add a response interceptor
  //   const responseInterceptor = axios.interceptors.response.use(
  //     (response) => {
  //       // If the response is successful, just return it
  //       return response;
  //     },
  //     (error) => {
  //       // If the response status is 401, handle it here
  //       if (error.response && error.response.status === 505) {
  //         if (!window.location.href.includes(process.env.REACT_APP_FRONT_URL+'/login')) {
  //           // Cookies.remove('mgltoken')
  //           // Replace the following line with your action, e.g., redirect to login page
  //           // window.location.href = process.env.REACT_APP_FRONT_URL+'/#/login?app='+process.env.REACT_APP_APP_KEY;
  //           notification.error({
  //               message: 'Błąd sesji: '+error,
  //               duration: 3
  //           });
  //           setTimeout(() => {
  //             window.location.href = '/';
  //           }, 3000)
  //         }
  //       }

  //       // If it's not a 401 response, just throw the error again
  //       return Promise.reject(error);
  //     }
  //   );

  //   // Cleanup the interceptors when the component unmounts
  //   return () => {
  //     axios.interceptors.request.eject(requestInterceptor);
  //     axios.interceptors.response.eject(responseInterceptor);
  //   };
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL+'/auth/session/check');
        // console.log(response.data)
        if (response.data.authenticated === true) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsAuthenticated(false);
      }
    };

    fetchData();
  }, [isAuthenticated]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL+'/auth/get/user');
        setGlobalUser(response.data)
      } catch (error) {
        console.error('Error fetching data:', error);

      }
    };

    fetchData();
  }, [isAuthenticated]);

  if (isAuthenticated === null && !isLoginPage) {
    return <></>;
  }

  if (isAuthenticated === false && !isLoginPage) {
    localStorage.setItem('lasturl', lastUrl);
    window.location.href = process.env.REACT_APP_FRONT_URL+'/#/login?app='+process.env.REACT_APP_APP_KEY;
    return false
  }
  if(localStorage.getItem('lasturl')){
    const lasturl = localStorage.getItem('lasturl');
    localStorage.removeItem('lasturl')
    return <Navigate to={lasturl} />;
  }
  if(isAuthenticated === true && isLoginPage){
    return <Navigate to="/" />;
  }
  if(isAuthenticated === false && isLoginPage){
    return <UserContext.Provider value={globalUser}>{children}</UserContext.Provider>;
  }
  if(isAuthenticated === true && !isLoginPage){
    return <UserContext.Provider value={globalUser}>{children}</UserContext.Provider>;
  }
};

export default PrivateRoute;
