import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { Modal, Button, Form, Input, Select } from "antd";
import { UserContext } from "../../../components/privateroute";
import axios from "axios";

export default function AddStructure(props) {
  const {setReload, reload} = props
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("numertelefonu"); // State to track the selected type
  const [form] = Form.useForm();
  const { ticketid } = useParams(); // Assuming ticketid is available in the route parameters
  const globalUser = useContext(UserContext)
  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    form.resetFields(); // Reset form fields upon modal close
    setSelectedType("numertelefonu"); // Reset selected type to default
  };

  const onOk = async () => {
    try {
      const values = await form.validateFields();
      const dataObject = {
        ...values,
        userid: globalUser.user.mlg_users_id, // Assuming this would be fetched or set from elsewhere
        ticketid: ticketid,
        dateline: Math.floor(Date.now() / 1000),
        opinion: 0,
        type: selectedType === "numertelefonu" ? 2 : selectedType === "adres" ? 1 : 3,
      };
      const response = await axios.post('/sprawa/add/new/structure', {
        structure: dataObject
      })
      setReload(!reload)
      closeModal();
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const onTypeChange = (value) => {
    setSelectedType(value); // Update the selected type state
    form.resetFields(["Numer telefonu", "Adres", "Link Google Maps", "Tekst notatki"]); // Reset other fields
  };

  const phoneNumberValidator = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Numer telefonu jest wymagany'));
    }
    // Regex to validate the phone number
    const isValidPhoneNumber = /^(\+\d{9,13}|\d{9,13})$/.test(value);
    if (!isValidPhoneNumber) {
      return Promise.reject(new Error('Numer telefonu musi posiadać od 9 do 13 cyfr i może zaczynać się od +'));
    }
    return Promise.resolve();
  };

  const renderFields = () => {
    switch (selectedType) {
      case "numertelefonu":
        return <><Form.Item name="text" label="Numer telefonu"
        rules={[
          { 
            validator: phoneNumberValidator,
          },
        ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="note" label="Tekst notatki">
            <Input.TextArea />
          </Form.Item>
        </>;
      case "adres":
        return <>
                 <Form.Item name="text" label="Adres" rules={[{ required: true }]}>
                   <Input />
                 </Form.Item>
                 <Form.Item name="link" label="Link Google Maps" rules={[{ required: true, type: 'url', warningOnly: true }]}>
                   <Input />
                 </Form.Item>
                 <Form.Item name="note" label="Tekst notatki">
                  <Input.TextArea />
                </Form.Item>
               </>;
      case "notatka":
        return <Form.Item name="text" label="Tekst notatki" rules={[{ required: true }]}>
                 <Input.TextArea />
               </Form.Item>;
      default:
        return null;
    }
  };

  return (
    <>
      <Button onClick={showModal}>
        Dodaj dane
      </Button>
      <Modal
        centered
        width={'700px'}
        open={modalOpen}
        title="Dodaj strukturyzowane dane"
        onOk={onOk}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Anuluj
          </Button>,
          <Button key="submit" type="primary" onClick={onOk}>
            Dodaj
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={{ type: "numertelefonu" }}>
          <Form.Item name="type" label="Typ" rules={[{ required: true }]}>
            <Select onChange={onTypeChange} value={selectedType}>
              <Select.Option value="numertelefonu">Numer telefonu</Select.Option>
              <Select.Option value="adres">Adres</Select.Option>
              <Select.Option value="notatka">Notatka</Select.Option>
            </Select>
          </Form.Item>
          {renderFields()}
        </Form>
      </Modal>
    </>
  );
}
