import React, {useEffect, useState} from "react"
import { Typography, Button, Modal, notification, Form, Input, Select } from "antd"
import axios from "axios"
import SingleArea from "./singleArea"
const {Text} = Typography
export default function Areas(props){
    const {setAreas, areas} = props
    

    const addArea = (name) => {
        // Generate a new unique ID for the new area
        const newId = 'new'+Math.floor(new Date().getTime());
        
        // Create a new area object with the specified name and all other values set to null
        const newArea = {
            id: newId, // Assuming a simple way to generate a unique ID
            name: name,
            woj: null,
            powiat: null,
            gmina: null,
        };

        // Update the state with the new areas array that includes the new area
        setAreas([...areas, newArea]);
    };

    const updateAreas = (id, key, value) => {
        // Update the state with the new areas array
        setAreas(areas.map(area => {
            if (area.id === id) {
                // Create a copy of the current area
                const updatedArea = { ...area, [key]: value };
    
                // If the 'woj' key is being updated, clear 'powiat' and 'gmina'
                if (key === 'woj') {
                    updatedArea.powiat = null;
                    updatedArea.gmina = null;
                }
                // If the 'powiat' key is being updated, clear 'gmina' only
                else if (key === 'powiat') {
                    updatedArea.gmina = null;
                }
    
                return updatedArea;
            }
            return area;
        }));
    };

    const removeAreaById = (id) => {
        // Update the state to filter out the area with the specified id
        setAreas(areas.filter(area => area.id !== id));
    };

    const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <>
    <div 
    style={{borderRadius: "5px", padding: "10px", border: "1px solid #E2E2E2", width: "100%", display: "flex",flexDirection: "column", gap: "20px"}}
    >
        <Text style={{fontSize: "14px", fontWeight: "600"}}>Obszary działań</Text>
        {areas.map((x)=> {
            return <SingleArea removeAreaById={removeAreaById} updateAreas={updateAreas} filterOption={filterOption} area={x}></SingleArea>
        })}
        <Button onClick={() => addArea("Nowy obszar")}>Dodaj nowy obszar</Button>
    </div>
</>
}