/**
 * The `AccessDenied` component displays a modal with a message indicating that the user does not have
 * sufficient permissions to access a certain area, and provides options to navigate back to the home
 * page.
 * @returns The AccessDenied component is returning a Modal component from the "antd" library. The
 * Modal component is being used to display a message to the user when they do not have sufficient
 * access rights. The Modal component has a title, content, and buttons for the user to close the
 * modal.
 */
import { Modal } from "antd";
import { useNavigate } from "react-router";

const AccessDenied = () => {
    const navigate = useNavigate()
    return <>
        <Modal 
            title="Brak dostępu"
            open={true} 
            width={1000}
            onOk={() => navigate("/")} 
            onCancel={() => navigate("/")} 
            centered
            >
            Wygląda na to, że nie posiadasz wystarczających uprawnień aby przejść do danego obszaru. Jeżeli uważasz, że to błąd skontaktuj się z administratorem lub działem technicznym.
        </Modal>
    </>
}
export default AccessDenied