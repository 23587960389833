import { Modal, Button, Select } from "antd";
import { useState, useEffect } from "react";
import { notification } from "antd";
import axios from "axios";
import filterOption from "../../../../../../components/filterOption";

export default function BulkWatchers(props) {
    const {tickets, reload, setReload} = props
    const [open, setOpen] = useState(false);
    const [userid, setUserid] = useState(null);
    const [values, setValues] = useState(null);

    useEffect(() => {
        if (open) {
            setValues(null);
            const fetchData = async () => {
                try {
                    const response = await axios.post("/sprawa/default/values", {
                        departmentid: null,
                    });
                    setValues([{ value: 0, label: 'BRAK PRZYPISANEGO PRACOWNIKA', hidden: false}, ...response.data.userid.filter(item => item.hidden != true)]);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
        }
    }, [open]);

    // /sprawy/save/bulk/users

    const saveBulkUsers = async () => {
        console.log({tickets: tickets, userid: userid})
        const response = await axios.post("/sprawy/save/bulk/watchers",{
            userids: userid,
            tickets: tickets
        });
        if(response.status == 200){
            notification.success({
                message: response.data.message,
                duration: 3
            });
        } else {
            notification.error({
                message: response.data.message,
                duration: 3
            });
        }
        setReload(!reload)
        setOpen(false);
    }

    return (
        <>
            <Modal
                centered={true}
                open={open}
                okText={'Zmień pracownika'}
                title="Przypisz do spraw pracownika"
                onOk={() => {
                    saveBulkUsers()
                }}
                onCancel={() => setOpen(false)}
            >
                <Select
                    name={"owneruserid"}
                    showSearch
                    mode="multiple"
                    onChange={(e) => setUserid(e)} 
                    options={values}
                    filterOption={filterOption}
                    size="large"
                    required
                    style={{
                        width: '100%'
                    }}
                />
            </Modal>
            <Button onClick={() => setOpen(true)}>Przypisz obserwatorów</Button>
        </>
    );
}
