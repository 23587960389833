const locale = {
    "lang": {
        "locale": "PL",
        "placeholder": "Wybierz dane",
        "rangePlaceholder": ["Data początkowa", "Data końcowa"],
        "today": "Dzisiaj",
        "now": "Teraz",
        "backToToday": "Wróć do dzisiaj",
        "ok": "OK",
        "clear": "Wyczyść",
        "month": "Miesiąc",
        "year": "Rok",
        "timeSelect": "Wybierz czas",
        "dateSelect": "Wybierz date",
        "monthSelect": "Wybierz miesiąc",
        "yearSelect": "Wybierz rok",
        "decadeSelect": "Wybierz dekade",
        "shortWeekDays": ["Nd", "Pon", "Wt", "Śr", "Czw", "Pt", "So"],
        "shortMonths": [
        "Sty",
        "Lu",
        "Marz",
        "Kw",
        "Maj",
        "Czer",
        "Lip",
        "Sier",
        "Wrz",
        "Paźdz",
        "Lis",
        "Gr"
        ]
    },
}
export default locale