import { Typography, Button, Modal, notification } from "antd"
import React, { useState } from "react"
import axios from "axios"
import TextEditor from "../../../components/textEditor"
import { UserContext } from "../../../components/privateroute"
export default function NoteModal(props){
    const {reload, setReload} = props
    const [modalOpen, setModalOpen] = useState(false)
    const [value, setValue] = useState(null)
    const globalUser = React.useContext(UserContext)
    const onOk = async () => {
        try {
            const response = await axios.post("/notatki/add",{
                createdat: Math.floor(new Date().getTime() / 1000),
                userid: globalUser.user.mlg_users_id,
                content: value
            });
            notification.success({
                message: "Notatka dodana.",
                duration: 3
            });
            setReload(!reload)
            setModalOpen(false)
            setValue(null)
        } catch (error) {
            notification.error({
                message: "Błąd serwera.",
                duration: 3
            });
        }
    }
    return <>
        <Button style={{}} onClick={() => setModalOpen(true)}>Nowa notatka</Button>
        <Modal 
            title="Dodaj nową notatkę"
            open={modalOpen} 
            width={1000}
            onOk={onOk} 
            onCancel={() => setModalOpen(false)} 
            centered
            >
        <TextEditor value={value} onChange={(e) => setValue(e)}></TextEditor>
        </Modal>
    </>
}