import React, { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme, Image, Space, Flex } from 'antd';
import SettingsTabs from './components/SettingsTabs';
const Ustawienia = () => {
  const [departs, setDeparts] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  return (
    <>
        <Breadcrumb
        style={{
            padding: '20px',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Ustawienia</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{padding: "20px", gap: "0px", width: '100%', background: "white",}}>
          <SettingsTabs></SettingsTabs>
        </div>
    </>
  );
};
export default Ustawienia;