import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import './index.css'
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {ConfigProvider, theme} from 'antd';
import PrivateRoute from "./routes/views/components/privateroute";
import Sprawy from "./routes/views/sprawy";
import Theme from './theme'
import AppLayout from "./routes/views/components/layout";
import Kalendarz from "./routes/views/kalendarz";
import NowaSprawa from "./routes/views/nowaSprawa";
import Sprawa from "./routes/views/sprawa";
import AccessDenied from "./routes/views/accessDeneid";
import Ustawienia from "./routes/views/ustawienia";
import Notatnik from "./routes/views/notatnik";
import Tagi from "./routes/views/tagi";
import DodatkowePola from "./routes/views/pola";
import plPL from 'antd/es/locale/pl_PL';
import dayjs from "dayjs";
import 'dayjs/locale/pl';
import Idle from "./routes/views/components/idletimer";
const root = ReactDOM.createRoot(document.getElementById("root"));
dayjs.locale('pl');
root.render(
  <ConfigProvider
  theme={Theme(theme)}
  locale={plPL}
  >
    <Idle></Idle>
    <Router>
      <Routes>
          <Route
            path="/sprawy"
            element={
              <PrivateRoute mainpath="/sprawy">
              <AppLayout content={
                  <Sprawy />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sprawy/:ticketid/:postid"
            element={
              <PrivateRoute mainpath="/sprawy">
              <AppLayout content={
                  <Sprawa />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sprawy/:ticketid"
            element={
              <PrivateRoute mainpath="/sprawy">
              <AppLayout content={
                  <Sprawa />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sprawy/nowa"
            element={
              <PrivateRoute mainpath="/sprawy/nowa">
              <AppLayout content={
                  <NowaSprawa />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/kalendarz"
            element={
              <PrivateRoute mainpath="/kalendarz">
              <AppLayout content={
                  <Kalendarz />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/ustawienia"
            element={
              <PrivateRoute mainpath="/ustawienia">
              <AppLayout content={
                  <Ustawienia />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/ustawienia/tagi"
            element={
              <PrivateRoute mainpath="/ustawienia">
              <AppLayout content={
                  <Tagi />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/ustawienia/pola"
            element={
              <PrivateRoute mainpath="/ustawienia">
              <AppLayout content={
                  <DodatkowePola></DodatkowePola>
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/notatnik"
            element={
              <PrivateRoute mainpath="/notatnik">
              <AppLayout content={
                  <Notatnik />
                }>
              </AppLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/access/denied"
            element={
              <AccessDenied />
            }
          />
          <Route path="*" element={<Navigate to="/sprawy" />} />
      </Routes>
    </Router>
    </ConfigProvider>
);
