import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { Breadcrumb, Space, Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TagsTable from './components/TagsTable';

const Tagi = () => {
  return (
    <>
      <Breadcrumb style={{ padding: '20px' }}>
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Ustawienia</Breadcrumb.Item>
        <Breadcrumb.Item>Tagi</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ padding: "20px", gap: "0px", width: '100%', background: "white" }}>
          <TagsTable></TagsTable>
      </div>
    </>
  );
};

export default Tagi;