import { Drawer, Space, Button, Divider } from "antd";
import ReminderCalendar from "../reminderCalendar";
import ReminderForm from "../reminderForm";
export default function ReminderDrawer(props){
    const {isOpen, setIsOpen, setSelectedDate, selectedDate, ticketid, setReload, reload} = props
    return <> 
    <Drawer
    title={`Dodaj przypomnienie do zgłoszenia`}
    placement="right"
    size={'large'}
    onClose={() => {setIsOpen(false)}}
    open={isOpen}
    extra={
      <Space>
        <Button onClick={() => setIsOpen(false)}>Anuluj</Button>
      </Space>
    }
  >
    <Divider>Wybierz dzień wydarzenia</Divider>
    <ReminderCalendar setValue={setSelectedDate} value={selectedDate}></ReminderCalendar>
    <Divider>Ustal dane przypomnienia</Divider>
    <ReminderForm reload={reload} setReload={setReload} isOpen={isOpen} setIsOpen={setIsOpen} ticketid={ticketid} selectedDate={selectedDate}></ReminderForm>
  </Drawer>
  </>
}