import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import axios from 'axios';
import Cookies from 'js-cookie';
import handleLogout from '../../login/components/logoutFunction';
import { Modal, Button, notification } from 'antd';

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
}

export default function Idle() {
  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const [renew, setRenew] = useState(null);
  const [remaining, setRemaining] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const timeout = 600 * 1000;
  const promptBeforeIdle = 120 * 1000;

  useEffect(() => {
    setRenew(setInterval(async () => {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/renew/session');
      Cookies.set('mgltokenprevious', Cookies.get('mgltoken'), { expires: 7, domain: process.env.REACT_APP_DOMAIN });
      Cookies.set('mgltoken', response.data.token, { expires: 7, domain: process.env.REACT_APP_DOMAIN });
      // console.log('Renewed session ' + response.data.token);
    }, 1000 * 60 * 10));
  }, []);

  useEffect(() => {
    // Add a request interceptor
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        config.headers['Authorization'] = Cookies.get('mgltoken') ? Cookies.get('mgltoken') : Cookies.get('mgltokenprevious');
        config.headers['App'] = process.env.REACT_APP_APP_KEY;
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // If the response is successful, just return it
        return response;
      },
      (error) => {
        // If the response status is 401, handle it here
        if (error.response && error.response.status === 505) {
          if (!window.location.href.includes(process.env.REACT_APP_FRONT_URL+'/login')) {
            // Cookies.remove('mgltoken')
            // Replace the following line with your action, e.g., redirect to login page
            // window.location.href = process.env.REACT_APP_FRONT_URL+'/#/login?app='+process.env.REACT_APP_APP_KEY;
            notification.error({
                message: 'Błąd sesji: '+error,
                duration: 3
            });
            setTimeout(() => {
              window.location.href = '/';
            }, 3000)
          }
        }

        // If it's not a 401 response, just throw the error again
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const onIdle = () => {
    clearTimeout(renew);
    handleLogout();
    setState('Idle');
    setOpenModal(false);
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const onPrompt = () => {
    setState('Prompted');
    setOpenModal(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    onPrompt,
    timeout: timeout,
    promptBeforeIdle: promptBeforeIdle,
    throttle: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = Math.ceil(getRemainingTime() / 1000);
      setRemaining(timeLeft);
      if (timeLeft <= promptBeforeIdle / 1000 && !openModal) {
        onPrompt(); // Ensure modal is prompted correctly based on timing
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [openModal]);

  const handleStillHere = () => {
    activate(); // Resets the timer
    setOpenModal(false); // Close the modal
  };

  return (
    <>
      <Modal
        footer={[
          <Button onClick={() => handleStillHere()}>Jestem aktywny!</Button>
        ]}
        title="Jesteś nieaktywny!"
        open={openModal}
        onOk={handleStillHere}
        onCancel={() => setOpenModal(false)}
      >
        <p>
          Jeżeli nie podejmiesz jakiejkolwiek czynności w ciągu <span style={{ color: 'red' }}>{formatTime(remaining)}</span> zostaniesz wylogowany a Twoje niezapisane postępy zostaną utracone!
        </p>
      </Modal>
    </>
  );
}
