import React, {useEffect, useState} from "react"
import { Typography, Button, Modal, notification, Form, Input, Select } from "antd"
import axios from "axios"
export default function SingleArea(props){
    const {updateAreas, area, filterOption, removeAreaById} = props
    const [wojs, setWojs] = useState(null) 
    const [powiat, setPowiat] = useState(null) 
    const [gmina, setGmina] = useState(null) 

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post("/sprawa/get/areas/default",{
                woj: area.woj,
                powiat: area.powiat
            });
            setWojs(response.data.wojs)
            setPowiat(response.data.powiaty)
            setGmina(response.data.gminy)
        }
        fetchData()
    },[area.powiat, area.gmina, area.woj])
    return <>
        <div style={{padding: "10px", border: "1px solid #E2E2E2", display: 'flex', flexDirection: "column", gap: '10px'}}>
            <Input 
            style={{fontWeight: "700"}}
                value={area.name}  
                placeholder="Nazwa obszaru"
                size="medium"
                name="name"
                onChange={(e) => updateAreas(area.id,"name",e.target.value)} 
            />
            <Select
                name={"woj"}
                showSearch
                value={area.woj}
                filterOption={filterOption}
                size="medium"
                required
                options={wojs}
                onChange={(e) => updateAreas(area.id,"woj",e)} 
            />
            <Select
                disabled={area.woj == null}
                name={"powiat"}
                showSearch
                value={area.powiat}
                filterOption={filterOption}
                size="medium"
                required
                options={powiat}
                onChange={(e) => updateAreas(area.id,"powiat",e)} 
            />
            <Select
                disabled={area.woj != null && area.powiat != null ? false : true}
                name={"gmina"}
                showSearch
                value={area.gmina}
                filterOption={filterOption}
                size="medium"
                required
                options={gmina}
                onChange={(e) => updateAreas(area.id,"gmina",e)} 
            />
            <Button onClick={() => removeAreaById(area.id)}>Usuń obszar</Button>
        </div>
    </>
}