import React from 'react';
import { Badge, Calendar, Flex, Breadcrumb } from 'antd';
const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};
export default function Kalendarz() {


  const dates = {
    "1_12_2023": [
      {
        type: 'warning',
        content: 'Spotkanie z klientem',
      },
      {
        type: 'success',
        content: 'Dodatkowe prace przy wpisach',
      },
      {
        type: 'error',
        content: 'Szybka zmiana danych w umowie',
      },
    ],
    "6_12_2023": [
      {
        type: 'warning',
        content: 'Spotkanie z klientem',
      },
      {
        type: 'success',
        content: 'Dodatkowe prace przy wpisach',
      },
      {
        type: 'error',
        content: 'Szybka zmiana danych w umowie',
      },
    ],
    "2_1_2024": [
      {
        type: 'warning',
        content: 'Restrukturyzacja umów na nowy rok',
      },
      {
        type: 'success',
        content: 'Dodatkowe prace przy wpisach',
      },
    ]
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = dates[value.date()+"_"+(value.month() + 1)+"_"+value.year()] != undefined ? dates[value.date()+"_"+(value.month() + 1)+"_"+value.year()] : [];
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };
  return (
    <>
        <Breadcrumb
        style={{
            padding: '20px',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Kalendarz</Breadcrumb.Item>
        </Breadcrumb>
        <Flex className={["fullHeight"]} align="start" style={{padding: "20px", gap: "0px", width: '100%', background: "white", height: "calc(100vh - 128px)"}}>
          <Calendar locale={{
            "lang": {
              "locale": "PL",
              "placeholder": "Wybierz dane",
              "rangePlaceholder": ["Data początkowa", "Data końcowa"],
              "today": "Dzisiaj",
              "now": "Teraz",
              "backToToday": "Wróć do dzisiaj",
              "ok": "OK",
              "clear": "Wyczyść",
              "month": "Miesiąc",
              "year": "Rok",
              "timeSelect": "Wybierz czas",
              "dateSelect": "Wybierz date",
              "monthSelect": "Wybierz miesiąc",
              "yearSelect": "Wybierz rok",
              "decadeSelect": "Choose a decade",
              "shortWeekDays": ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
              "shortMonths": [
                "Styczeń",
                "Luty",
                "Marzec",
                "Kwiecień",
                "Maj",
                "Czerwiec",
                "Lipiec",
                "Sierpień",
                "Wrzesień",
                "Październik",
                "Listopad",
                "Grudzień"
              ]
            },
          }} cellRender={cellRender} />
        </Flex>
    </>
  );
};