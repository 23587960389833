import React, {useEffect, useState} from "react"
import { Typography, Button, Modal, notification, Form, Input, DatePicker, Select, Badge } from "antd"
import filterOption from "../../../../../components/filterOption";
import dayjs from "dayjs";
import axios from "axios"
import saveToLocalStorage from "../../../../../functions/saveToLocalStorage";
export default function AdvancedSearching(props){
    const {setPagination, pagination} = props
    const [modalOpen, setModalOpen] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [values, setValues] = useState(null)
    const [administration, setAdministration] = useState(null) 
    const [searchFields, setSearchFields] = useState([
        {
            name: "swtickets.dateline",
            label: "Data utworzenia",
            type: "dateline",
            range: true,
        },
        {
            name: "swtickets.accident_dateline",
            label: "Data zdarzenia",
            type: "dateline",
            range: true
        },
        {
            name: "swtickets.lastactivity",
            label: "Ostatnia aktywność",
            type: "dateline",
            range: true
        },
        {
            name: "swtickets.ticketstatusid",
            label: "Status sprawy",
            type: "select",
            multi: true,
            defaultValues: "ticketstatusid"
        },
        {
            name: "stl.tagid",
            label: "Tagi sprawy",
            type: "select",
            multi: true,
            defaultValues: "tags"
        },
        {
            name: "swtickets.priorityid",
            label: "Priorytet sprawy",
            type: "select",
            multi: true,
            addable: true,
            defaultValues: "priorityid"
        },
        {
            name: "swticketwatchers.staffid",
            label: "Obserwator",
            type: "select",
            multi: true,
            addable: true,
            defaultValues: "userid"
        },
        {
            name: "swtickets.userid",
            label: "Zgłaszający",
            type: "select",
            multi: true,
            addable: true,
            defaultValues: "userid"
        },
        {
            name: "swtickets.owneruserid",
            label: "Przypisany",
            type: "select",
            multi: true,
            addable: true,
            defaultValues: "userid"
        },
        {
            name: "special",
            label: "Tylko sprawy specjalne",
            type: "select",
            defaultValues: "special"
        },
        {
            name: "favorite",
            label: "Tylko sprawy obserwowane",
            type: "select",
            defaultValues: "favorite"
        },
        {
            name: "swadministrationelementsticket.woj",
            label: "Województwo",
            type: "select",
            defaultValues: null,
            dynamicValues: administration?.wojs,
            dynamicKey: "wojs"
        },
        {
            name: "swadministrationelementsticket.powiat",
            label: "Powiat",
            type: "select",
            defaultValues: null,
            dynamicValues: administration?.powiaty,
            dynamicKey: "powiaty",
            disabledBy: "swadministrationelementsticket.woj"
        },
        {
            name: "swadministrationelementsticket.gmina",
            label: "Gmina",
            type: "select",
            defaultValues: null,
            dynamicValues: administration?.gminy,
            dynamicKey: "gminy",
            disabledBy: "swadministrationelementsticket.powiat"
        }
    ])
    useEffect(() => {   
        if(localStorage.getItem('advancedSearch') != null){
            const advancedSearch = JSON.parse(localStorage.getItem('advancedSearch'))
            setSearchFields(currentFields =>
                currentFields.map(field => {
                    const value = advancedSearch.find(advField => advField.name === field.name)?.value;
                    return { ...field, value };
                })
            );
        }
    }, [localStorage.getItem('advancedSearch')])
    useEffect(() => {
        if(administration != null){
            // Logic to update select options in searchFields based on administration
            const updatedSearchFields = searchFields.map(field => {
                if('dynamicValues' in field){
                    if (field.type === "select" && administration[field.dynamicKey]) {
                        return { ...field, dynamicValues: administration[field.dynamicKey] };
                    }
                }
                return field;
            });
            setSearchFields(updatedSearchFields);
        }
    }, [administration]); // Depend on administration to update searchFields
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post("/sprawa/get/areas/default",{
                woj: searchFields.find(field => field.name == 'swadministrationelementsticket.woj')?.value,
                powiat: searchFields.find(field => field.name == 'swadministrationelementsticket.powiat')?.value
            });
            setAdministration({
                "wojs": response.data.wojs,
                "powiaty": response.data.powiaty,
                'gminy': response.data.gminy
            })
        }
        fetchData()
    },[searchFields.find(field => field.name == 'swadministrationelementsticket.woj').value, searchFields.find(field => field.name == 'swadministrationelementsticket.powiat').value])
    useEffect(() => {
        setSearchFields(currentFields =>
            currentFields.map(field => {
              if (field.name === "swadministrationelementsticket.powiat") {
                return { ...field, value: null};
              }
              return field;
            })
        );
    },[searchFields.find(field => field.name == 'swadministrationelementsticket.woj').value])
    useEffect(() => {
        setSearchFields(currentFields =>
            currentFields.map(field => {
              if (field.name === "swadministrationelementsticket.gmina") {
                return { ...field, value: null};
              }
              return field;
            })
        );
    },[searchFields.find(field => field.name == 'swadministrationelementsticket.powiat').value])
    const onCancel = () => {
        setModalOpen(false)
    }
    const onOk = () => {
        setModalOpen(false)
        const fieldsWithNameAndValue = searchFields
        .filter(field => 
            field.value !== undefined && 
            field.value !== null && 
            (Array.isArray(field.value) ? field.value.some(val => val !== null) : true)
        )
        .map(field => ({
            name: field.name,
            value: field.value
        }));
        console.log(fieldsWithNameAndValue)
        localStorage.setItem('advancedSearch', JSON.stringify(fieldsWithNameAndValue));
        saveToLocalStorage('currentPage',1)
        setPagination({...pagination, advancedSearch: fieldsWithNameAndValue, current: 1})
    }
    const removeValueKey = () => {
        setSearchFields(currentFields =>
            currentFields.map(({ value, ...rest }) => ({
                ...rest
            }))
        );
    };
    const updateSearchField = (fieldName, inputValue, dateFrom = null, dateTo = null) => {
        setSearchFields(currentFields =>
            currentFields.map(field => {
                if (field.name === fieldName && field.range) {
                    // Extract existing start and end dates
                    const [existingFrom, existingTo] = field.value || [null, null];
    
                    // Determine new start and end dates
                    const newFrom = dateFrom === '' ? null : (dateFrom !== null ? dateFrom : existingFrom);
                    const newTo = dateTo === '' ? null : (dateTo !== null ? dateTo : existingTo);
                    
                    // Clear value if both dateFrom and dateTo are empty
                    if (newFrom === null && newTo === null) {
                        return { ...field, value: null };
                    }
    
                    // Update only if there's a change
                    if (newFrom !== existingFrom || newTo !== existingTo) {
                        return { ...field, value: [newFrom, newTo] };
                    }
    
                } else if (field.name === fieldName) {
                    // Handle non-range fields
                    if (!inputValue || inputValue.length === 0) {
                        // If the input value is empty, clear the field
                        return { ...field, value: null };
                    }
    
                    let newValue;
                    if (Array.isArray(inputValue)) {
                        newValue = [inputValue]; // Wrap input array in another array
                    } else {
                        newValue = [[inputValue]]; // Wrap single value in an array of arrays
                    }
    
                    return { ...field, value: newValue };
                }
                return field; // Return the field unchanged if not the target of update
            })
        );
    };
    
    
    useEffect(() => {
        setValues(null)
        const fetchData = async () => {
            const response = await axios.post("/sprawa/default/values");
            setValues(response.data)
        }
        fetchData()
    },[])
    return <>
        <Badge count={
            (searchFields
            .filter(field => 
                field.value !== undefined && 
                field.value !== null && 
                (Array.isArray(field.value) ? field.value.some(val => val !== null) : true)
            )
            .map(field => ({
                name: field.name,
                value: field.value
            }))).length
        }>
            <Button onClick={() => setModalOpen(true)}>Wyszukiwanie zaawansowane</Button>
        </Badge>
        
        <Modal 
            title="Wyszukaj sprawy"
            open={modalOpen} 
            width={1000}
            onOk={onOk} 
            onCancel={onCancel} 
            centered
            confirmLoading={confirmLoading}
            footer={[
                <Button key="back" onClick={onCancel}>
                  Zamknij
                </Button>,
                <Button key="custom" onClick={removeValueKey}>
                  Wyczyść filtry
                </Button>,
                <Button key="submit" type="primary" onClick={onOk}>
                  Wyszukaj
                </Button>
            ]}
            >
            {values ? 
            <Form layout="vertical" style={{marginTop: "20px", display: "grid", gridTemplateColumns: "1fr 1fr", gridColumnGap: "20px"}}>
                {administration != null ? searchFields.map((x)=>{
                    return <>
                    {x.type == "dateline" ?
                    x.range == true ? 
                        <>
                        <Form.Item
                            label={x.label + " OD"}
                        >
                                <DatePicker
                                placeholder="Wybierz datę początkową"
                                style={{width: "100%"}}
                                format="YYYY-MM-DD"
                                name={x.name}
                                value={searchFields.find(field => field.name == x.name)?.value ? searchFields.find(field => field.name == x.name)?.value[0] != null ? dayjs(new Date((searchFields.find(field => field.name == x.name)?.value[0]) * 1000)) : "" : ""}
                                onChange={(date, dateString) => updateSearchField(x.name, null, dateString != "" ? Math.floor(new Date(dateString).getTime() / 1000) : "", null)}
                                disabledDate={(currentDate) => {
                                    if(searchFields.find(field => field.name == x.name)?.value){
                                        if(searchFields.find(field => field.name == x.name)?.value[1] != null){
                                            const currentTimestamp = currentDate ? currentDate.unix() : null;
                                            return currentTimestamp ? currentTimestamp > searchFields.find(field => field.name == x.name)?.value[1] : false;
                                        } else {
                                            return false
                                        }
                                    }
                                }}
                                ></DatePicker>
                        </Form.Item>
                        <Form.Item
                            label={x.label  + " DO"}
                        > 
                                <DatePicker
                                placeholder="Wybierz datę końcową"
                                style={{width: "100%"}}
                                format="YYYY-MM-DD"
                                name={x.name}
                                value={searchFields.find(field => field.name == x.name)?.value ? searchFields.find(field => field.name == x.name)?.value[1] != null ? dayjs(new Date((searchFields.find(field => field.name == x.name)?.value[1]) * 1000)) : "" : ""}
                                onChange={(date, dateString) => updateSearchField(x.name, null, null, dateString != "" ? Math.floor(new Date(dateString).getTime() / 1000) : "")}
                                disabledDate={(currentDate) => {
                                    if(searchFields.find(field => field.name == x.name)?.value){
                                        const currentTimestamp = currentDate ? currentDate.unix() : null;
                                        return currentTimestamp ? currentTimestamp < searchFields.find(field => field.name == x.name)?.value[0] : false;
                                    }
                                }}
                                ></DatePicker>
                        </Form.Item>
                        </>
                        :
                        <Form.Item
                            label={x.label}
                        >
                        <DatePicker
                                placeholder="Wybierz datę"
                                style={{width: "100%"}}
                                format="YYYY-MM-DD"
                                name={x.name}
                                value={searchFields.find(field => field.name == x.name)?.value ? dayjs(new Date((searchFields.find(field => field.name == x.name)?.value) * 1000)) : ""}
                                onChange={(date, dateString) => updateSearchField(x.name, dateString != "" ? Math.floor(new Date(dateString).getTime() / 1000) : "")}
                                ></DatePicker>
                        </Form.Item> 
                    : <></>}
                    {x.type == "select" && x.addable != true ?
                    <>
                    <Form.Item
                        label={<>

                        <span>{x.label}</span>
                        </>}
                    >
                        <Select
                            name={x.name}
                            showSearch
                            allowClear
                            mode={x.multi ? 'multiple' : ''}
                            options={x.defaultValues == null ? x.dynamicValues : values[x.defaultValues]}
                            value={searchFields.find(field => field.name === x.name)?.value?.[0] || []}  // Handling multi-values correctly
                            filterOption={filterOption}
                            size="medium"
                            required
                            onChange={(e) => updateSearchField(x.name, e)}
                            disabled={'disabledBy' in x ? !searchFields.find(field => field.name == x.disabledBy)?.value : false}
                        />

                    </Form.Item>
                    </>
                    : 
                    <></>
                    }
                    {x.type == "select" && x.addable == true ?
                    <>
                    <Form.Item
                        label={<>
                        {/* <Button>+ALBO</Button> */}
                        <span>{x.label}</span>
                        </>}
                    >
                        <Select
                            name={x.name}
                            showSearch
                            allowClear
                            mode={x.multi ? 'multiple' : ''}
                            options={x.defaultValues == null ? x.dynamicValues : values[x.defaultValues]}
                            value={searchFields.find(field => field.name === x.name)?.value?.[0] || []}  // Handling multi-values correctly
                            filterOption={filterOption}
                            size="medium"
                            required
                            onChange={(e) => updateSearchField(x.name, e)}
                            disabled={'disabledBy' in x ? !searchFields.find(field => field.name == x.disabledBy)?.value : false}
                        />

                    </Form.Item>
                    </>
                    : 
                    <></>
                    }
                    </> 
                }): null}
            </Form>
            : <></>}
        </Modal>
    </>
}