import { Switch, notification } from "antd";
import axios from "axios";

const handleSwitchChange = (tagid, setInitialData) => {
    // console.log('Switch toggled for tag ID:', tagid);
    setInitialData(prevData => {
        const active = !prevData.find(item => item.tagid === tagid).active;
        axios.post(`/tag/update/status/`, { tagid: tagid, value: active })
            .then(response => {
                notification.success({
                    message: response.data.message,
                });
            })
            .catch(error => {
                notification.success({
                    message: error.response.data.message,
                });
            });
        const newData = prevData.map((item) => {
            if (item && item.tagid === tagid) {
                return { ...item, active: !item.active };
            }
            return item;
        });
        return newData;
    });
};

export default function Columns(props){
    const {setInitialData, initialData} = props;
    const columns = [
        {
            title: 'Nazwa tagu',
            dataIndex: 'tagname',
            key: 'tagname',
        },
        {
            title: 'Aktywny/Nieaktywny',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => (
                <Switch checked={!!(record && record.active)} onChange={() => handleSwitchChange(record && record.tagid, setInitialData)} />
            ),
        },
    ];
    return columns;
}
