import React, { useState, useCallback } from 'react';

// Custom hook that returns a function to be used as onChange handler
export default function useDebouncedOnChange(updateValue) {
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleChange = useCallback((e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newValue = e.target.value;

    // Set a timeout to update the state after 1 second of inactivity
    const timeout = setTimeout(() => {
      updateValue(newValue);
    }, 1400);

    setTypingTimeout(timeout);
  }, [updateValue, typingTimeout]);

  return handleChange;
};
