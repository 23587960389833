import React, { useEffect, useState } from 'react';
import { Form, Input, Select, TimePicker, Button, notification } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import { UserContext } from '../../privateroute';
const { Option } = Select;

const ReminderForm = (props) => {
  const { ticketid, setIsOpen, selectedDate, setReload, reload } = props;
  const [form] = Form.useForm();
  const [values, setValues] = useState(null)
  const globalUser = React.useContext(UserContext)
  // Initialize the state for TimePicker with the selected date or the current time
  const [selectedTime, setSelectedTime] = useState(selectedDate ? dayjs(selectedDate) : dayjs());

  const onTimeChange = (time) => {
    setSelectedTime(time);
  };

  const onFinish = async (values) => {
    const newDateline = dayjs(selectedDate)
        .hour(values.reminderTime)
        .minute(0)
        .second(0)
        .millisecond(0);
    var valueToSend = {
        ...values,
        startdateline: (new Date(newDateline).getTime() / 1000),
        enddateline: (new Date(newDateline).getTime() / 1000),
        userid: globalUser.user.mlg_users_id,
        ticketid: ticketid
    }
    delete(valueToSend.reminderTime)
    const response = await axios.post("/reminders/add",valueToSend);
    if(response.data.status){
      setIsOpen(false)
      notification.success({
          message: "Dodano przypomnienie",
          duration: 3
      });
      setReload(new Date().getTime())
    } else {
        notification.error({
            message: "Błąd. Spróbuj ponownie",
            duration: 3
        });
    }

  };

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

  useEffect(() => {
      setValues(null)
      const fetchData = async () => {
          const response = await axios.post("/sprawa/default/values",{
              departmentid: null
          });
          setValues(response.data)
      }
      fetchData()
  },[])

  const getValues = (e, y) => {
    let data = values[e];
    data = data.filter(item => item.hidden === false);
    return data
}
const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    const hourStr = hour.toString().padStart(2, '0'); // Ensures two digits
    options.push(<Option key={hourStr} value={`${hourStr}`}>{`${hourStr}:00`}</Option>);
  }
  return options;
};

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="eventname"
        label="Tytuł przypomnienia"
        rules={[{ required: true, message: 'Proszę wpisać tytuł przypomnienia!' }]}
      >
        <Input placeholder="Wpisz tytuł przypomnienia" />
      </Form.Item>
      {values &&
      <Form.Item 
      name="addusers"
      label="Dodatkowe osoby do przypomnienia"
      style={{marginBottom: "10px"}}>
        <Select
            mode="multiple"
            options={getValues("watchers", "watchers")}
            filterOption={filterOption}
        />
    </Form.Item>}
        <div className='flexFormCol2'>
      <Form.Item
        name="priority"
        label="Priorytet"
        rules={[{ required: true, message: 'Proszę wybrać priorytet!' }]}
      >
        <Select placeholder="Wybierz priorytet">
          <Option value="3">Wysoki</Option>
          <Option value="2">Średni</Option>
          <Option value="1">Niski</Option>
        </Select>
      </Form.Item>

      <Form.Item
      name="reminderTime"
      label="Godzina przypomnienia"
      rules={[{ required: true, message: 'Proszę wybrać godzinę przypomnienia!' }]}
    >
      <Select
        style={{ width: '100%' }}
        value={selectedTime}
        onChange={onTimeChange}
        placeholder="Wybierz godzinę"
      >
        {generateTimeOptions()}
      </Select>
    </Form.Item>
      </div>
      <div className='flexFormCol2'>
      <Form.Item
        name="mail"
        label="Wysyłka e-mail"
        rules={[{ required: true, message: 'Proszę wybrać opcję wysyłki e-mail!' }]}
      >
        <Select placeholder="Wybierz opcję">
          <Option value="1">Tak</Option>
          <Option value="0">Nie</Option>
        </Select>
      </Form.Item>

      <Form.Item

        name="sms"
        label="SMS"
        rules={[{ required: false, message: 'Proszę wybrać opcję SMS!' }]}
      >
        <Select placeholder="Wybierz opcję" disabled value={'0'} defaultValue={'0'}>
          <Option value="1">Tak</Option>
          <Option value="0">Nie</Option>
        </Select>
      </Form.Item>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Zapisz przypomnienie
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReminderForm;