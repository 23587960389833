import React, { useState } from 'react';
import { Calendar } from "antd";
import locale from "../../../../../config/calendars";
import dayjs from 'dayjs';
export default function ReminderCalendar(props) {
    const {value, setValue} = props
    const [mode, setMode] = useState('month'); // State to manage calendar mode
    const disabledDate = (current) => {
        // Disable dates before today
        return current && current < dayjs().startOf('day');
    };
    const onSelect = (newValue) => {
        if(mode == 'year'){
            setMode('month')
        }
        setValue(newValue);
    };
  
    const onPanelChange = (newValue, newMode) => {
        if(newMode != 'month'){
            setMode(currentMode => currentMode === 'month' ? 'year' : 'month');
        }
        setValue(newValue);
    };
    return (
        <>
            <Calendar 
                disabledDate={disabledDate}
                value={dayjs(value)} 
                mode={mode} 
                onSelect={onSelect} 
                onPanelChange={onPanelChange} 
                locale={locale}
                picker="year"
            />
        </>
    );
}
