import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {Form, Breadcrumb, Layout, Menu, theme, Image, Space, Flex, Spin, Skeleton } from 'antd';
import { useNavigate } from 'react-router';
import TitlePost from './components/NewPostAsTitle';
import SideBar from './components/sideBar';
import Title from 'antd/es/skeleton/Title';
import FastEnters from '../../../components/fastEnters';
import generateRandomString from './components/generateRandomString';
import { UserContext } from '../components/privateroute';
import { useLocation } from 'react-router';
const { Header, Content, Footer } = Layout;


const NowaSprawa = () => {
  const navigate = useNavigate()
  let location = useLocation();
  const globalUser = React.useContext(UserContext);
  const [values, setValues] = useState(null)
  const [credentials, setCredentials] = useState({
    subject: "",
    newcomment: location.state != null ? location.state.content : '',
    noteid: location.state != null ? location.state.noteid : null,
    ticketmaskid: generateRandomString(),
    isemailed: 0,
    waitingroom: 0,
    accident_dateline: location.state != null ? location.state.date : (Math.floor(new Date().getTime() / 1000))
  });

  // Update credentials when globalUser is loaded
  useEffect(() => {
    if (globalUser && Object.keys(globalUser).length > 0) {
      // Assuming globalUser has properties like id, name, email
      setCredentials(prevCreds => ({
        ...prevCreds,
        owneruserid: globalUser.user.mlg_users_id,
        userid: globalUser.user.mlg_users_id,
        fullname: globalUser.user.mlg_users_fullname,
        email: globalUser.contacts.find(contact => contact.type === 'E-mail')?.value
      }));
    }
  }, [globalUser, values]); 

  useEffect(() => {
    if(globalUser.user){
      if(credentials.waitingroom == 1){
        setCredentials({
          ...credentials,
          owneruserid: 0,
        })
      } else {
        setCredentials({
          ...credentials,
          owneruserid: globalUser.user.mlg_users_id,
        })
      }
    }
  }, [credentials.waitingroom, globalUser])
  useEffect(() => {
    if(credentials.owneruserid != 0 && credentials.waitingroom == 1){
      setCredentials({
        ...credentials,
        owneruserid: 0,
      })
    }
  }, [credentials.owneruserid])

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getValues = (e, y) => {
    if(e == "userid" || e == "watchers") {
        var data = values[e]
        if(e == "userid"){
            const valueToCheck = credentials[y]; // The value you're checking for

            // Check if the value exists in the object and modify the `hidden` property
            data = data.map(item => {
                if (item.value === valueToCheck) {
                    return { ...item, hidden: false }; // Change hidden to 0 for the specified value
                }
                return item;
            });

            // Remove all elements with `hidden` equal to 1
            data = data.filter(item => item.hidden === false);
            return data
        }
        if(e == "watchers"){
            const valuesToCheck = credentials[y]; // The values you're checking for as an array

            // Check if the value exists in the valuesToCheck array and modify the `hidden` property
            if(valuesToCheck){
              data = data.map(item => {
                  if (valuesToCheck.includes(item.value)) {
                      return { ...item, hidden: false }; // Change hidden to 0 for the specified values
                  }
                  return item;
              });
            }

            // Remove all elements with `hidden` equal to 1
            data = data.filter(item => item.hidden === false);
            return data
        }
      } else if(e == "tags") {
        // console.log(values[e])
        var data = values[e]
        const valuesToCheck = credentials[y]; // The values you're checking for as an array
        // console.log(valuesToCheck)

        // Check if the value exists in the valuesToCheck array and modify the `hidden` property
        if(valuesToCheck){
            data = data.map(item => {
            if (valuesToCheck.includes(item.value)) {
                return { ...item, hidden: false }; // Change hidden to 0 for the specified values
            }
            return item;
            });
        }

        // Remove all elements with `hidden` equal to 1
        data = data.filter(item => item.hidden === false);
        return data
    } else {
        return values[e]
    }
}

  useEffect(() => {
      setValues(null)
      const fetchData = async () => {
          const response = await axios.post("/sprawa/default/values",{
              departmentid: credentials.departmentid != undefined ? credentials.departmentid : 2
          });
          setCredentials({
              ...credentials,
              priorityid: response.data["priorityid"][1],
              ticketstatusid: response.data["ticketstatusid"][0],
              departmentid: credentials.departmentid != undefined ? credentials.departmentid : 2
          })
          setValues(response.data)
      }
      fetchData()
  },[credentials.departmentid])

  const handleDataChange = (value, key) => {
    setCredentials((prevCredentials) => ({
        ...prevCredentials,
        [key]: value,
    }));
  };

  return (
    <>
        <Breadcrumb
        style={{
            padding: '20px',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/sprawy")}><span style={{cursor: "pointer"}}>Sprawy</span></Breadcrumb.Item>
        <Breadcrumb.Item>Nowa sprawa</Breadcrumb.Item>
        </Breadcrumb>
        <FastEnters></FastEnters>
        <Form layout='vertical'>
        <Flex align="start" style={{gap: "0px", width: '100%', background: "white", height: "calc(-175px + 100vh)"}}>
          { values && (credentials.owneruserid || credentials.owneruserid == 0) ? <> 
            <TitlePost setCredentials={setCredentials} credentials={credentials} globalUser={globalUser} filterOption={filterOption} getValues={getValues} setValues={setValues} values={values} handleDataChange={handleDataChange}></TitlePost>
            <SideBar setCredentials={setCredentials} credentials={credentials} globalUser={globalUser} filterOption={filterOption} getValues={getValues} setValues={setValues} values={values} handleDataChange={handleDataChange}/>
            </> : <>
            <div className={'post newticket'} style={{padding: "20px",width: "100%"}}>
              <Skeleton.Avatar size={'large'} active />
              <Skeleton active />
              <div className='newform'>
              <Skeleton active />
              </div>
          </div>
          <div direction='vertical' style={{zIndex: "10",overflowY: "auto", boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", paddingBottom: '20px', height: "100%", width: "30%", maxWidth: '400px', alignItems: "flex-start", display: "flex", flexDirection: "column", gap: "20px"}}>
              <div style={{width: "100%"}}>
              <div style={{padding: "20px 10px", background: 'white', position: 'sticky', top: '0', zIndex: 5, boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.12)', marginBottom: '10px'}}>
              <Skeleton.Button active block={true}/>
              </div>
              <div style={{padding: "5px 10px",}}>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </div>
              </div>
          </div>
            </>
          }
        </Flex>
        </Form>
    </>
  );
};
export default NowaSprawa;