import React, {useEffect, useState} from "react"
import { useParams } from "react-router"
import { Typography, Button, Modal, notification, Form, Input, DatePicker, Select, Badge } from "antd"
export default function SendNotifications(props){
    const {saveCredentialsPost, saveCredentials, saveLoading, setSaveLoading, styleButton, reload, setReload} = props
    const [modalOpen, setModalOpen] = useState(false)
    const { ticketid, postid } = useParams();
    const showModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
      const onOk = async (type) => {
        try {
            // Set loading state before starting both save functions
            setSaveLoading(true);
    
            // Wait for saveCredentials to finish before moving to the next step
            await saveCredentials(type);
    
            // Wait for saveCredentialsPost to finish
            await saveCredentialsPost(type);
    
            // Once both are done, close the modal
            setModalOpen(false);
    
            // Trigger reload only after both functions are completed
            setReload(!reload);
        } catch (error) {
            console.error("Error during the save process:", error);
            notification.error({
                message: "An error occurred during the save process.",
                duration: 3
            });
        } finally {
            // Disable the loading state after everything is done
            setSaveLoading(false);
        }
    };
    return <>
      <Button onClick={showModal} style={styleButton}>
        Zapisz i wyślij...
      </Button>
      <Modal
        centered={true}
        width={'1000px'}
        open={modalOpen}
        title="Czy chcesz wysłać powiadomienie o zmianie?"
        onCancel={closeModal}
        footer={[
          <Button style={{marginTop: '5px'}} key="back" onClick={closeModal}>
            Anuluj
          </Button>,
          <Button style={{marginTop: '5px'}} key="submit_owner" loading={saveLoading} onClick={() => onOk('owner')}>
            Do przypisanego pracownika w sprawie #{ticketid}
          </Button>,
          <Button style={{marginTop: '5px'}} key="submit_creator" loading={saveLoading} onClick={() => onOk('creator')}>
            Do twórcyw sprawy #{ticketid}
          </Button>,
          <Button style={{marginTop: '5px'}} key="submit_watchers" loading={saveLoading} onClick={() => onOk('watchers')}>
            Do obserwatorów sprawy #{ticketid}
          </Button>,
          <Button style={{marginTop: '5px'}} key="submit_all" loading={saveLoading} onClick={() => onOk('all')}>
            Do wszystkich w sprawie #{ticketid}
          </Button>,
          <Button style={{marginTop: '5px'}} key="submit_save" type="primary" loading={saveLoading} onClick={() => onOk(null)}>
            Tylko zapisz
          </Button>,
        ]}
      >
        <p>Wybierz do kogo wysłać powiadomienia o zmianach wprowadzonych w sprawie po jej zapisaniu. Możesz zapisać także bez wysyłania powiadomienia</p>
      </Modal>
    </>
}