import { useState, useEffect } from "react"
import ReminderDrawer from "./reminderDrawer"
import { Button } from "antd"
export default function Reminder(props){
    const {ticketid, setReload, reload} = props
    const [isOpen, setIsOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
    return <>
    <ReminderDrawer reload={reload} setReload={setReload} ticketid={ticketid} isOpen={isOpen} setIsOpen={setIsOpen} selectedDate={selectedDate} setSelectedDate={setSelectedDate}></ReminderDrawer>
    <Button onClick={() => setIsOpen(true)}>Zaplanuj przypomnienie</Button>
    </>
}