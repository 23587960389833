export default function generateRandomString() {
    // Function to generate a random uppercase letter
    function randomLetter() {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        return alphabet[Math.floor(Math.random() * alphabet.length)];
    }

    // Function to generate a random digit
    function randomDigit() {
        return Math.floor(Math.random() * 10);
    }

    // Construct the string based on the pattern LLL-NNN-NNNNN
    let result = "";
    for (let i = 0; i < 3; i++) {
        result += randomLetter();
    }
    result += "-";
    for (let i = 0; i < 3; i++) {
        result += randomDigit();
    }
    result += "-";
    for (let i = 0; i < 5; i++) {
        result += randomDigit();
    }

    return result;
}