import { Button } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import isCan from "../../../../../../components/isCan";
import CanWrapper from "../../../../../../components/Can";
export default function SpecialTimeSetter(props) {
    const {special, setSpecial, ticketid, setReload, reload, setLoader, loader} = props
    const [canTurnOn, setCanTurnOn] = useState(null)
    const [canTurnOff, setCanTurnOff] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
          const permittedOn = await isCan(["can_turn_on_specials"])
          if(permittedOn){
            setCanTurnOn(true)
          } else {
            setCanTurnOn(false)
          }
          const permittedOff = await isCan(["can_turn_off_specials"])
          if(permittedOff){
            setCanTurnOff(true)
          } else {
            setCanTurnOff(false)
          }
        }
        fetchData()
    },[])
    const stopSpecialTime = async () => {
        setLoader(true)
        const responce = await axios.post(`/specialtime/stop`, {
            ticketid: ticketid.ticketid,
            id: special
        })
        setReload(!reload)
    }
    const startSpecialTime = async () => {
        setLoader(true)
        const responce = await axios.post(`/specialtime/start`, {
            ticketid: ticketid.ticketid,
        })
        setReload(!reload)
    } 
    

    return <>
    <CanWrapper perms={["can_turn_on_specials"]}>
      {
        (special != false ? 
          canTurnOff ? <Button loading={loader} type="primary" danger style={{width: '100%', marginBottom: '10px'}} onClick={() => stopSpecialTime()}>Wyłącz sprawę specjalną</Button> : <></>
              :
          canTurnOn ? <Button loading={loader} style={{width: '100%', marginBottom: '10px'}} onClick={() => startSpecialTime()}>Włącz sprawę specjalną</Button> : <></>
          )
      }
    </CanWrapper>
    </>
}