import React, { useState, useEffect } from 'react';
import { Table, Spin, Flex, Badge, Select, Space, Radio, Input, Typography, Button, Tooltip, notification } from 'antd';
import { useNavigate } from 'react-router';
import axios from 'axios';
import {
  SearchOutlined,
  FilterOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import useDebouncedOnChange from '../../../../../../functions/useDebouncedOnChange';
import datetimeParser from '../../../../../../functions/datetimeParser';
import CaseStatus from '../../../../components/caseStatus';
import SimpleAvatar from '../../../../components/simpleAvatar';
import HighlightShort from '../../../../../../components/HighlightShort';
import { UserContext } from '../../../../components/privateroute';
const {Text} = Typography

const DataTableModal = (props) => {
  const {value, setValue, credentials} = props
  const globalUser = React.useContext(UserContext);
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [departs, setDeparts] = useState([])
  const [user, setUser] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
    list: 'none',
    search: "",
    orderby: []
  });
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);
  const setSearch = (value) => {
    setPagination({...pagination, search: value})
  }
  
  const debouncedOnChange = useDebouncedOnChange(setSearch);
  const fetchData = async (page, pageSize, search) => {
    try {
      var ticketIDS = credentials.connectedTickets.map(item => item.ticketid)
      ticketIDS.push(credentials.ticketid)
      setLoading(true);
      const resperms = await axios.post(process.env.REACT_APP_API_URL+'/perms/get/by/user');
      const userPerms = resperms.data;
      const response = await axios.post('/sprawy/list/posts', {
        pagination: { ...pagination, current: page, pageSize: pageSize, search: search},
        departs: departs,
        isadmin: userPerms.includes('can_see_all_tickets'),
        exlude: ticketIDS
      });

      setData(response.data.rows);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, pagination.search);
  }, [pagination.current, pagination.pageSize, pagination.search, reload, departs, credentials]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'ticketid',
      render: (item, record) => {
        return <div>
          <Radio onChange={() => setValue(record.ticketid)} value={record.ticketid}></Radio>
          </div>
      },
    },
    {
      title: 'Sprawa',
      dataIndex: 'subject',
      key: 'subject',
      render: (item, record) => {
        return <div style={{maxWidth: "1000px", minWidth: "300px", cursor: 'pointer'}} onClick={() => setValue(record.ticketid)}>
          <Text style={{fontSize:'16px', display: "block"}}>{item}</Text>
          <Text style={{fontSize:'12px',color: "rgba(0, 0, 0, 0.60)", display: "block"}}>{datetimeParser(record.dateline, 'YYYY-MM-DD HH:mm:ss')}</Text>
          {pagination.search == '' ? <></> : <HighlightShort textContent={record.contents} searchString={pagination.search}/>}
          </div>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item, record) => {
        return <CaseStatus record={record}/>
      },
    },
    {
      title: 'Zgłaszający',
      dataIndex: 'phone',
      key: 'phone',
      render: (item, record) => {
        return <SimpleAvatar user={record.userDetails}></SimpleAvatar>
      }
    },
  ];

  return (
    <>
    <div>
    <Space.Compact className='smallResCol' size="large" style={{ width:'100%', gap: "10px"}}>
      <Input onChange={debouncedOnChange} addonBefore={<SearchOutlined />} placeholder="Wyszukaj sprawę | #ID, Tytuł, treść" />
      <Flex style={{gap: "10px"}}>
      </Flex>
    </Space.Compact>
    <div>
    <Spin spinning={loading}>
    <Radio.Group value={value} style={{width: '100%'}}>
      <Table
      size="small"
        columns={columns}
        dataSource={data}
        pagination={{
          ...pagination,
          position: ["bottomRight"],
          showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} elementów`, // Customize total display
        }}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Brak danych' }}
      />
    </Radio.Group>
    </Spin>
    </div>
    </div>
    </>
  );
};

export default DataTableModal;