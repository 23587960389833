import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Form, Tree, Space, Card, Typography, Select, Avatar } from 'antd';
import { UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import datetimeParser from '../../../../../functions/datetimeParser';
import DOMPurify from 'dompurify';
import UrlPreview from '../../../components/urlPreview';
import FilePreview from '../../../components/filePreview';
const {Text, Title} = Typography


function isDatelineInRange(objects, dateline) {
    if(objects.length > 0){
        for (let obj of objects) {
          // Check if dateline_end is null
          if (obj.dateline_end === null) {
            // Check if the given dateline is greater than dateline_start
            if (dateline > obj.dateline_start) {
              return true;
            }
          } else {
            // Check if the given dateline is between dateline_start and dateline_end
            if (dateline >= obj.dateline_start && dateline <= obj.dateline_end) {
              return true;
            }
          }
        }
        // If none of the objects match the criteria, return false
        return false;
    } else {
        return false
    }
}


const LogsAsContent = (props) => {
    const {data, showLogs, specialPeriods} = props

    return (
        <div className='post' style={isDatelineInRange(specialPeriods, data.dateline) ? {display: (showLogs ? "grid" : "none"), gridTemplateColumns: "46px auto", columnGap: "20px",padding: "10px 0px", borderBottom: "1px solid #D3D3D3", background: '#f9dbdb', paddingLeft: '15px', borderLeft: '3px solid red'} : {display: (showLogs ? "grid" : "none"), gridTemplateColumns: "46px auto", columnGap: "20px",padding: "10px 0px", borderBottom: "1px solid #D3D3D3"}}>
            <div className='post row first'>
                <Avatar style={{backgroundColor: "#00000030", color: "black", textTransform: "uppercase", borderRadius: "5px"}} shape="square" size={46}><InfoCircleOutlined /></Avatar>
            </div>
            <div className='post row second'>
                <div>
                <Title style={{margin: "0px"}} level={5}>{data.userDetails ? data.userDetails.mlg_users_fullname : ''}</Title>
                <Text style={{margin: "0px", color: "#9B9B9B"}}>{datetimeParser(data.dateline, 'YYYY-MM-DD HH:mm:ss')}</Text>
                </div>
                <div dangerouslySetInnerHTML={{ __html: data.actionmsg }} />
            </div>
        </div>
    );
};
export default LogsAsContent;