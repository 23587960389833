
import { Button, Drawer, Modal, Menu, Dropdown } from "antd"
import {DownOutlined} from '@ant-design/icons'
import CanWrapper from "../../../../../components/Can"
import { useState } from "react"
import BulkUsers from "./components/bulkUsers"
import BulkWatchers from "./components/bulkWatchers"
import BulkAddToList from "./components/bulkList"
export default function BulkEdit(props){
    const {ids, reload, setReload} = props
    const [open, setOpen] = useState(false)
    const menu = <Menu style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
        <CanWrapper perms={["can_save_ticket"]}>
            <Menu.Item style={{padding: 0, background: 'white'}} key="1">
                <BulkUsers setReload={setReload} reload={reload} tickets={ids}></BulkUsers>
            </Menu.Item>
            <Menu.Item style={{padding: 0, background: 'white'}} key="2">
                <BulkWatchers setReload={setReload} reload={reload} tickets={ids}></BulkWatchers>
            </Menu.Item>
        </CanWrapper>
        <Menu.Item style={{padding: 0, background: 'white'}} key="2">
            <BulkAddToList setReload={setReload} reload={reload} tickets={ids}></BulkAddToList>
        </Menu.Item>
    </Menu>

    return <>
    
    {ids.length > 0 ? <>
        <Dropdown overlay={menu} trigger={["click"]} onOpenChange={(flag) => setOpen(flag)} open={open}>
            <Button>
                Akcje hurtowe <DownOutlined />
            </Button>
        </Dropdown>
    </> : <></>}
    </>
}