import React, { useEffect, useState } from "react";
import { Typography, Button, Modal, notification, Form, Input, DatePicker, Select, Badge } from "antd";
import filterOption from "../../../../../components/filterOption";
import dayjs from "dayjs";
import axios from "axios";

export default function UserRaport(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDate, setSelectedDate] = useState(dayjs()); // Default to current year and month
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const users = await axios.post('/sprawy/get/users');
            const clear = users.data.filter(x => x.mlg_users_active == 1);
            setUsers(clear.map(x => { return { label: x.mlg_users_fullname, value: x.mlg_users_id }}));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onOk = async () => {
        if (!selectedUser || !selectedDate) {
            notification.error({ message: 'Wybierz pracownika oraz datę.' });
            return;
        }

        const selectedMonth = selectedDate.month() + 1; // Month is 0-indexed in dayjs
        const selectedYear = selectedDate.year();

        const raport = await axios.post('/sprawy/get/raport/users', {
            userid: selectedUser,
            username: users.find(x => x.value == selectedUser).label,
            month: selectedMonth,
            year: selectedYear
        });

        axios({
            url: `/files/download/global/${raport.data.filename}`, // Replace with your server URL
            method: 'POST',
            responseType: 'blob', // Important
        }).then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            const match = contentDisposition.match(/filename="(.+)"/);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', match[1]); // Replace with filename and extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            console.error('Download error:', error);
        });
    };

    const onCancel = () => {
        setModalOpen(false);
    };

    const disabledDate = (current) => {
        // Disable future dates, allowing only past and present
        return current && current > dayjs().endOf('month');
    };

    return (
        <>
            <Button onClick={() => setModalOpen(true)}>Raport pracownika</Button>
            <Modal
                title="Wybierz pracownika do raportu"
                open={modalOpen}
                onOk={onOk}
                onCancel={onCancel}
                centered
                confirmLoading={confirmLoading}
                footer={[
                    <Button key="back" onClick={onCancel}>
                        Zamknij
                    </Button>,
                    <Button key="submit" type="primary" onClick={onOk}>
                        Wybierz
                    </Button>
                ]}
            >
                <Form.Item label="Przypisany pracownik" style={{ margin: "0px" }}>
                    <Select
                        name={"userid"}
                        showSearch
                        onChange={(e) => setSelectedUser(e)}
                        options={users}
                        filterOption={filterOption}
                        size="large"
                        required
                    />
                </Form.Item>

                <Form.Item label="Wybierz miesiąc i rok" style={{ margin: "20px 0" }}>
                    <DatePicker
                        onChange={(date) => setSelectedDate(date)}
                        picker="month" // Month and Year picker
                        disabledDate={disabledDate} // Disable future dates
                        size="large"
                        placeholder="Wybierz miesiąc i rok"
                        value={selectedDate} // Set default date to current month and year
                        defaultValue={dayjs()} // Default value as the current month and year
                    />
                </Form.Item>
            </Modal>
        </>
    );
}
