import React, { useEffect, useState } from 'react';
import { Badge, Drawer, Typography, Button } from "antd"
import { MenuOutlined, AlertOutlined, CheckOutlined } from '@ant-design/icons';
import datetimeParser from '../../../../../../functions/datetimeParser';
import { useNavigate } from 'react-router';
import axios from 'axios';
const { Title, Text} = Typography;
export default function Noti(props) {
    const {onClose, x, globalUser} = props
    const navigate = useNavigate()
    const [animate, setAnimate] = useState(false)
    const [isVisible, setIsVisible] = useState(true)
    const [notifications, setNotifications] = useState(null)
    const [showed, setShowed] = useState(false)
    const actionRedirect = (url) => {
        navigate(url)
        onClose()
    }
    const setStatus = (status) => {
        switch(status) {
            case 1:
              return '#0075E1'
              break;
            case 2:
              return '#E1B000'
              break;
            case 3:
              return '#E10000'
              break;
            default:
            return '#0075E1'
        }
    }
    const checkNotification = async (id) => {
        const response = await axios.post('/notifications/check/by/id', {
            userid: globalUser.user.mlg_users_id,
            id: id
        }); 
        setAnimate(true)
    }
    useEffect(() => {
        if (animate) {
          setTimeout(() => {
            setIsVisible(false);
          }, 1000); // 500ms matches the CSS transition duration
        }
    }, [animate]);
    return <div style={{
        padding: "10px",
        background: "white",
        borderRadius: "10px",  
        boxShadow: "0px 3px 1.6px 0px rgba(184, 184, 184, 0.25)",
        marginBottom: '10px',
        transitionDuration: '1s',
        transform: animate ? 'translate(100%)' : 'none',
        opacity: animate ? '0' : '1',
        display: isVisible ? 'block' : 'none'
        }}>
            <div onClick={() => {setShowed(!showed)}} style={{cursor: 'pointer', display: 'flex', alignItems: "center", gap: "10px"}}>
                <AlertOutlined style={{background: setStatus(x.status), padding: '10px', borderRadius: '100%', fontSize: '20px', cursor: "pointer", color: "white" }}/>
                <div>
                    <Title style={{margin: '0'}} level={5}>{x.title}</Title>
                    <Text style={{margin: '0'}} level={5}>{datetimeParser(x.dateline, 'YYYY-MM-DD HH:mm:ss')}</Text>
                </div>
            </div>
            <div style={{display: showed ? "block" : "none"}}>
            <div style={{marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
                <Text>{x.message}</Text>
            </div>
            <div style={{marginTop: '10px', display: 'flex', justifyContent: 'space-between'}}>
                <Button icon={<CheckOutlined />} onClick={() => checkNotification(x.swnotificationid)}></Button>
                <Button onClick={() => actionRedirect(x.actionurl)}>ZOBACZ</Button>
            </div>
            </div>
        </div>
}