import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Flex, Typography, Image, Spin, Button } from 'antd';
import { FileImageOutlined, FilePdfOutlined, FileTextOutlined, FileOutlined, DownloadOutlined } from '@ant-design/icons';
import datetimeParser from '../../../../functions/datetimeParser';

const { Text, Title } = Typography;

function getIcon(value) {
    switch (value) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
        case 'image/webp':
            return <FileImageOutlined />;
        case 'application/pdf':
            return <FilePdfOutlined />;
        case 'application/msword':
            return <FileTextOutlined />;
        default:
            return <FileOutlined />;
    }
}

function isImage(value) {
    return ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(value);
}

const FilePreview = ({ data }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    const downloadFile = (filename) => {
        axios({
            url: `/files/download/${filename}`, // Replace with your server URL
            method: 'POST',
            responseType: 'blob', // Important for file download
        })
            .then((response) => {
                const contentDisposition = response.headers['content-disposition'];
                const match = contentDisposition.match(/filename="(.+)"/);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', match[1] || filename); // Use filename from header or fallback to passed name
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                console.error('Download error:', error);
            });
    };

    const fetchImage = () => {
        if (isImage(data.filetype)) {
            axios({
                url: `/files/download/${data.storefilename}`,
                method: 'POST',
                responseType: 'blob',
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    setImageUrl(url);
                })
                .catch((error) => {
                    console.error('Error fetching image:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImage();
    }, [data.storefilename, data.filetype]);

    // Handle hover for download icon
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <>
            {!isImage(data.filetype) ? (
                <div
                    style={{
                        height: 'fit-content',
                        display: 'flex',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        background: 'white',
                        boxShadow: '1px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                        width: '250px',
                        marginTop: '10px',
                    }}
                >
                    <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                            width: '70px',
                            cursor: 'pointer',
                            backgroundColor: 'rgb(189, 129, 54)',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '30px',
                        }}
                    >
                        {isHovered ? <DownloadOutlined onClick={() => downloadFile(data.storefilename)} /> : getIcon(data.filetype)}
                    </div>
                    <div
                        style={{
                            padding: '10px',
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Title style={{ margin: '0px', fontSize: '14px', fontWeight: '400', marginBottom: '5px' }}>
                            {data.filename}
                        </Title>
                        <Flex style={{ justifyContent: 'space-between' }}>
                            <Text style={{ margin: '0px', fontSize: '12px', fontWeight: '600' }}>{(data.filesize / 1000000).toFixed(2)} MB</Text>
                            <Text style={{ margin: '0px', fontSize: '12px', fontWeight: '400', color: '#969696' }}>
                                {datetimeParser(data.dateline, 'YYYY-MM-DD HH:mm:ss')}
                            </Text>
                        </Flex>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        borderRadius: '5px',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        background: 'white',
                        boxShadow: '1px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                        width: '250px',
                        marginTop: '10px',
                    }}
                >
                    {loading ? (
                        <div style={{ width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin spinning={true} />
                        </div>
                    ) : (
                        <Image
                            width={'100%'}
                            height={'200px'}
                            style={{ objectFit: 'cover' }}
                            src={imageUrl}
                            alt={data.filename}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            overflow: 'hidden',
                            background: 'white',
                            boxShadow: '1px 0px 2px 0px rgba(0, 0, 0, 0.25)',
                            width: '250px',
                        }}
                    >
                        <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{
                                width: '70px',
                                cursor: 'pointer',
                                backgroundColor: 'rgb(189, 129, 54)',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '30px',
                            }}
                        >
                            {isHovered ? <DownloadOutlined onClick={() => downloadFile(data.storefilename)} /> : getIcon(data.filetype)}
                        </div>
                        <div
                            style={{
                                padding: '10px',
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Title style={{ margin: '0px', fontSize: '14px', fontWeight: '400', marginBottom: '5px' }}>
                                {data.filename}
                            </Title>
                            <Flex style={{ justifyContent: 'space-between' }}>
                                <Text style={{ margin: '0px', fontSize: '12px', fontWeight: '600' }}>{(data.filesize / 1000000).toFixed(2)} MB</Text>
                                <Text style={{ margin: '0px', fontSize: '12px', fontWeight: '400', color: '#969696' }}>
                                    {datetimeParser(data.dateline, 'YYYY-MM-DD HH:mm:ss')}
                                </Text>
                            </Flex>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FilePreview;
