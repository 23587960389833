import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import {Form,Button, Tree, Flex,  Space, Card, Typography, notification, Select, Spin } from 'antd';
import Areas from '../../../components/Areas';
import SendNotifications from '../../../sprawa/components/sendNotifications';
const {Text} = Typography

function isValueInArray(array, value) {
    return array.some(element => element.value === value);
}

const SideBar = (props) => {
    const {credentials, setCredentials, globalUser, filterOption, getValues, setValues, values, handleDataChange} = props
    const navigate = useNavigate()
    const [saveLoading, setSaveLoading] = useState(false)
    const [areas, setAreas] = useState([])

    const addNew = async (sendtype = null) => {
        if(credentials.subject != ''){
            const response = await axios.post("/sprawa/add/new",{
                credentials: credentials,
                areas: areas,
                sendtype: sendtype
            });
            navigate("/sprawy/"+response.data.id)
        } else {
            notification.error({
                message: "Tytuł sprawy nie może być pusty. Tytułu sprawy nie da się później zmienić!",
                duration: 3
            })
        }
    }

    return (
        <div direction='vertical' style={{zIndex: "10",overflowY: "auto", boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", paddingBottom: '20px', height: "100%", width: "30%", maxWidth: '400px', alignItems: "flex-start", display: "flex", flexDirection: "column", gap: "20px"}}>
            <div style={{width: "100%"}}>
            <Spin spinning={values == null}>
            <div style={{padding: "20px 10px", background: 'white', position: 'sticky', top: '0', zIndex: 5, boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.12)', marginBottom: '10px'}}>
            <Flex style={{width: "100%", gap: "10px"}}>
                <Button onClick={() => navigate("/sprawy")}>Anuluj</Button>
                <SendNotifications saveCredentialsPost={() => {}} saveCredentials={addNew} saveLoading={saveLoading} setSaveLoading={setSaveLoading}></SendNotifications>
            </Flex>
            </div>
            <div style={{padding: "5px 10px",}}>
            <div style={{borderRadius: "5px", padding: "5px 10px", border: "1px solid #E2E2E2", width: "100%", display: "flex",flexDirection: "column", gap: "20px"}}>
                <Text style={{fontSize: "14px", fontWeight: "600"}}>Dane zgłoszenia</Text>
                    {values != null ?
                    <>
                    <Form.Item label='Przypisany pracownik' style={{margin: "0px"}}>
                    <Select
                        name={"owneruserid"}
                        showSearch
                        onChange={(e) => handleDataChange(e, "owneruserid")} 
                        options={[{value: 0, label: 'BRAK PRZYPISANEGO PRACOWNIKA', hidden: false},...getValues("userid")]}
                        getValues
                        defaultValue={credentials.owneruserid}
                        value={credentials.owneruserid}
                        filterOption={filterOption}
                        size="large"
                        required
                    />
                    </Form.Item>
                    <Form.Item label='Status' style={{margin: "0px"}}>
                    <Select
                        name={"ticketstatusid"}
                        onChange={(e) => handleDataChange(e, "ticketstatusid")} 
                        options={getValues("ticketstatusid")}
                        defaultValue={credentials.ticketstatusid}
                        size="large"
                        required
                    />
                    </Form.Item>
                    <Form.Item label='Priorytet' style={{margin: "0px"}}>
                        <Select
                            name={"priorityid"}
                            onChange={(e) => handleDataChange(e, "priorityid")} 
                            options={getValues("priorityid")}
                            defaultValue={credentials.priorityid}
                            size="large"
                            required
                        />
                    </Form.Item>
                    <Form.Item label='Obserwatorzy' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"watchers"}
                            onChange={(e) => handleDataChange(e, "watchers")} 
                            options={getValues("watchers")}
                            size="large"
                            filterOption={filterOption}
                        />
                    </Form.Item>
                    <Form.Item label='Kody rejestracyjne powiatu' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"registerplates"}
                            onChange={(e) => handleDataChange(e, "registerplates")} 
                            options={getValues("registerplates")}
                            defaultValue={credentials["registerplates"]}
                            value={credentials["registerplates"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    <Form.Item label='Tagi' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"tags"}
                            onChange={(e) => handleDataChange(e, "tags")} 
                            options={getValues("tags", "tags")}
                            defaultValue={credentials["tags"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    <Form.Item label='Kody pocztowe' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"postcodes"}
                            onChange={(e) => handleDataChange(e, "postcodes")} 
                            options={getValues("postcodes")}
                            defaultValue={credentials["postcodes"]}
                            value={credentials["postcodes"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    </>
                    : <></>}
                </div>
                </div>
            </Spin>
            </div>
            <div style={{padding: "5px 10px", width: '100%'}}>
            <Areas areas={areas} setAreas={setAreas}></Areas>
            </div>
        </div>
    );
};
export default SideBar;