import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Avatar, Flex, Typography } from 'antd'
const {Text} = Typography
export default function SimpleAvatar(props) {
    const {user} = props
    return (<>
        {user ? <Flex align='center' justify='left' gap={10}>
            <Avatar style={{background: "black", height: "32px", width: "32px"}}>
                <Text style={{color: "white", textTransform: "uppercase"}}>{user.mlg_users_fullname[0]}{user.mlg_users_fullname[1]}</Text>
            </Avatar>
            <span style={{textWrap: "no-wrap", flex: '1'}}>
            {user.mlg_users_fullname}
            </span>
        </Flex> : <><div style={{padding: '5px 20px', background: 'rgb(255, 77, 79)', color: 'white'}}>BRAK PRZYPISANIA</div></>}
    </>)
}
