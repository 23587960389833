import React, { useState } from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, Space } from 'antd';
import Logo from '../../assets/images/MarshalLogo.png'
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Option 1', '1', <PieChartOutlined />),
  getItem('Option 2', '2', <DesktopOutlined />),
  getItem('User', 'sub1', <UserOutlined />),
  getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  getItem('Files', '9', <FileOutlined />),
];
const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
      <Sider style={{background: "white"}} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <Space style={{padding: '20px'}}>
        <Image src={Logo}></Image>
        </Space>
        <Menu defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
  );
};
export default Home;