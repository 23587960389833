import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import {
    MenuOutlined
} from '@ant-design/icons';
import {Form,Button, Tree, Flex,  Space, Card, Typography, notification, Select, Spin, Checkbox, DatePicker } from 'antd';
import { UserContext } from '../../../components/privateroute';
import ConnectedTickets from '../connectedTickets';
import Areas from '../../../components/Areas';
import SpecialTimeSetter from '../specialTime/specialTimeSetter';
import SendNotifications from '../sendNotifications';
import AdditionalFields from '../additionalFields';
import CanWrapper from '../../../../../components/Can';
import dayjs from 'dayjs';
const {Text} = Typography

function isValueInArray(array, value) {
    return array.some(element => element.value === value);
}

const SideBar = (props) => {
    const globalUser = React.useContext(UserContext);
    const {saveCredentialsPost, special, setSpecial, posts, setPosts, ticket, setTicket, showLogs, setShowLogs, setReload, reload, areas, setAreas, setLoader, loader} = props
    const [credentials, setCredentials] = useState(ticket)
    const navigate = useNavigate()
    const [values, setValues] = useState(null)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [fields, setFields] = useState({})
    const [saveLoading, setSaveLoading] = useState(false)
    const [showAccidentdate, setShowAccidentdate] = useState(true)

    const getValues = (e, y) => {
        if(e == "userid" || e == "watchers") {
            var data = values[e]
            if(e == "userid"){
                const valueToCheck = credentials[y]; // The value you're checking for

                // Check if the value exists in the object and modify the `hidden` property
                data = data.map(item => {
                    if (item.value === valueToCheck) {
                        return { ...item, hidden: false }; // Change hidden to 0 for the specified value
                    }
                    return item;
                });

                // Remove all elements with `hidden` equal to 1
                data = data.filter(item => item.hidden === false);
                return data
            }
            if(e == "watchers"){
                const valuesToCheck = credentials[y]; // The values you're checking for as an array

                // Check if the value exists in the valuesToCheck array and modify the `hidden` property
                if(valuesToCheck){
                data = data.map(item => {
                    if (valuesToCheck.includes(item.value)) {
                        return { ...item, hidden: false }; // Change hidden to 0 for the specified values
                    }
                    return item;
                });
                }

                // Remove all elements with `hidden` equal to 1
                data = data.filter(item => item.hidden === false);
                return data
            }
        } else if(e == "tags"){
            // console.log(values[e])
            var data = values[e]
            const valuesToCheck = credentials[y]; // The values you're checking for as an array
            // console.log(valuesToCheck)

            // Check if the value exists in the valuesToCheck array and modify the `hidden` property
            if(valuesToCheck){
                data = data.map(item => {
                if (valuesToCheck.includes(item.value)) {
                    return { ...item, hidden: false }; // Change hidden to 0 for the specified values
                }
                return item;
                });
            }

            // Remove all elements with `hidden` equal to 1
            data = data.filter(item => item.hidden === false);
            return data
        } else {
            return values[e]
        }
    }

    

    useEffect(() => {
        setValues(null)
        const fetchData = async () => {
            const response = await axios.post("/sprawa/default/values",{
                departmentid: credentials.departmentid
            });
            setValues(response.data)
        }
        fetchData()
    },[credentials.departmentid, ticket])

    useEffect(() => {
        setCredentials(ticket)
        setShowAccidentdate(ticket.accident_dateline ? true : false)
    }, [ticket])


    const handleDataChange = (value, key) => {
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [key]: value == "NaN" ? null : value,
        }));
    };

    const saveCredentials = async (sendType = null) => {
        try {
            setSaveLoading(true);
            
            // Wait for the server to complete the update
            const response = await axios.post("/sprawa/save/values", {
                userid: globalUser.user.mlg_users_id,
                credentials: credentials,
                ticketid: ticket,
                areas: areas,
                fields: fields,
                sendtype: sendType
            });
    
            // Log the request for debugging purposes
            console.log("TEST: ", {
                userid: globalUser.user.mlg_users_id,
                credentials: credentials,
                ticketid: ticket,
                areas: areas,
                fields: fields,
                sendtype: sendType
            });
    
            // Wait a little to ensure everything is processed, but only if necessary
            await new Promise(resolve => setTimeout(resolve, 200));
    
            // Handle the response and show success or failure notifications
            if (response.status === 200) {
                notification.success({
                    message: response.data.message,
                    duration: 3
                });
            } else {
                notification.error({
                    message: response.data.message,
                    duration: 3
                });
            }
        } catch (error) {
            console.error("Error during save: ", error);
            notification.error({
                message: "An error occurred while saving",
                duration: 3
            });
        } finally {
            // Disable the loading state
            setSaveLoading(false);
        }
    };
    

    const onChangeCheckbox = (e) => {
        setShowLogs(e.target.checked)
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
        {mobileOpen ? <></> : <div className='button-sidebaropener' onClick={() => setMobileOpen(!mobileOpen)}>
            <MenuOutlined></MenuOutlined>
            <div>Dane sprawy</div>
        </div>}
        <div className={mobileOpen ? 'sidebar-wrapper side-opened' : 'sidebar-wrapper'} direction='vertical' style={{zIndex: "10",paddingBottom: '20px', overflowY: "auto", boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)", height: "100%",  width: "300px", flexShrink: 'none', alignItems: "flex-start", display: "flex", flexDirection: "column", gap: "20px"}}>
            <div style={{width: "100%"}}>
            <Spin spinning={values == null}>
            <div style={{padding: "20px 10px", background: 'white', position: 'sticky', top: '0', zIndex: 5, boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.12)', marginBottom: '10px'}}>
                <div className='sidebaropener' onClick={() => setMobileOpen(!mobileOpen)}>
                    Zwiń panel boczny
                </div>
                <SpecialTimeSetter loader={loader} setLoader={setLoader} ticketid={ticket} reload={reload} setReload={setReload} special={special} setSpecial={setSpecial}></SpecialTimeSetter>
                <CanWrapper perms={['can_access_logs']}><Checkbox style={{flexDirection: 'row-reverse', float: "right", marginBottom: "5px"}} onChange={onChangeCheckbox}>Pokaż logi systemowe</Checkbox></CanWrapper>
                <Flex style={{width: "100%", gap: "10px"}}>
                    <Button onClick={() => navigate("/sprawy")}>Powrót</Button>
                    {/* <Button style={{width: "100%"}} onClick={saveCredentials} loading={saveLoading}>Zapisz</Button> */}
                    <CanWrapper perms={['can_save_ticket']}><SendNotifications styleButton={{width: '100%'}} saveCredentials={saveCredentials} saveCredentialsPost={saveCredentialsPost} saveLoading={saveLoading} setSaveLoading={setSaveLoading} setReload={setReload} reload={reload}></SendNotifications></CanWrapper>
                </Flex>
            </div>
            <div style={{padding: "5px 10px", width: '100%'}}>
            <div style={{borderRadius: "5px", padding: "10px", border: "1px solid #E2E2E2", width: "100%", display: "flex",flexDirection: "column", gap: "20px"}}>
                <Text style={{fontSize: "14px", fontWeight: "600"}}>Dane zgłoszenia</Text>
                    {values != null ?
                    <>
                    <Form.Item label='Przypisany pracownik' style={{margin: "0px"}}>
                    <Select
                        name={"owneruserid"}
                        showSearch
                        onChange={(e) => handleDataChange(e, "owneruserid")} 
                        options={[{value: 0, label: 'BRAK PRZYPISANEGO PRACOWNIKA', hidden: false}, ...getValues("userid", "owneruserid")]}
                        defaultValue={credentials["owneruserid"]}
                        filterOption={filterOption}
                        size="large"
                        required
                    />
                    </Form.Item>
                    <Form.Item label='Status' style={{margin: "0px"}}>
                    <Select
                        name={"ticketstatusid"}
                        onChange={(e) => handleDataChange(e, "ticketstatusid")} 
                        options={getValues("ticketstatusid")}
                        defaultValue={isValueInArray(getValues("ticketstatusid"),credentials["ticketstatusid"]) ? credentials["ticketstatusid"] : getValues("ticketstatusid")[0].value}
                        size="large"
                        required
                    />
                    </Form.Item>
                    {showAccidentdate ? <>                    
                    <CanWrapper perms={["can_change_accident_date"]}>
                    <Form.Item label='Data zdarzenia' style={{margin: "0px"}}>
                        <DatePicker 
                            style={{width: '100%'}}
                            size="large"
                            defaultValue={credentials["accident_dateline"] != 'null' && credentials["accident_dateline"] ? dayjs.unix(credentials["accident_dateline"]) : ''} 
                            onChange={(date, datestring) => handleDataChange(dayjs(date).unix(), "accident_dateline")}
                            disabledDate={current => current && current > dayjs().endOf('day')}
                        />
                    </Form.Item>
                    </CanWrapper>
                    </> : <>
                    <Form.Item label='Data zdarzenia' style={{margin: "0px"}}>
                        <DatePicker 
                            style={{width: '100%'}}
                            size="large"
                            defaultValue={credentials["accident_dateline"] != 'null' && credentials["accident_dateline"] ? dayjs.unix(credentials["accident_dateline"]) : ''} 
                            onChange={(date, datestring) => handleDataChange(dayjs(date).unix(), "accident_dateline")}
                            disabledDate={current => current && current > dayjs().endOf('day')}
                        />
                    </Form.Item>
                    </>}
                    <Form.Item label='Priorytet' style={{margin: "0px"}}>
                        <Select
                            name={"priorityid"}
                            onChange={(e) => handleDataChange(e, "priorityid")} 
                            options={getValues("priorityid")}
                            defaultValue={credentials["priorityid"]}
                            size="large"
                            required
                        />
                    </Form.Item>
                    {/* <Form.Item label='Dział' style={{margin: "0px"}}>
                        <Select
                            name={"departmentid"}
                            onChange={(e) => handleDataChange(e, "departmentid")} 
                            options={getValues("departmentid")}
                            defaultValue={credentials["departmentid"]}
                            size="large"
                            required
                        />
                    </Form.Item> */}
                    <Form.Item label='Obserwatorzy' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"watchers"}
                            onChange={(e) => handleDataChange(e, "watchers")} 
                            options={getValues("watchers", "watchers")}
                            defaultValue={credentials["watchers"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    <Form.Item label='Kody rejestracyjne powiatu' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"registerplates"}
                            onChange={(e) => handleDataChange(e, "registerplates")} 
                            options={getValues("registerplates")}
                            defaultValue={credentials["registerplates"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    <Form.Item label='Tagi' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"tags"}
                            onChange={(e) => handleDataChange(e, "tags")} 
                            options={getValues("tags", "tags")}
                            defaultValue={credentials["tags"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    <Form.Item label='Kody pocztowe' style={{margin: "0px"}}>
                        <Select
                            mode="multiple"
                            name={"postcodes"}
                            onChange={(e) => handleDataChange(e, "postcodes")} 
                            options={getValues("postcodes")}
                            defaultValue={credentials["postcodes"]}
                            value={credentials["postcodes"]}
                            size="large"
                            filterOption={filterOption}
                            required
                        />
                    </Form.Item>
                    </>
                    : <></>}
                </div>
                </div>
            </Spin>
            </div>
            {/* <div style={{padding: '5px 10px', width: '100%'}}>
            <AdditionalFields setFields={setFields} fields={fields} reload={reload} setReload={setReload} ticketid={ticket.ticketid}></AdditionalFields>
            </div> */}
            <div style={{padding: '5px 10px', width: '100%'}}>
            <ConnectedTickets setReload={setReload} reload={reload} credentials={credentials}></ConnectedTickets>
            </div>
            <div style={{padding: '5px 10px', width: '100%'}}>
            <Areas areas={areas} setAreas={setAreas}></Areas>
            </div>
        </div>
        </>
    );
};
export default SideBar;