import React,{ useState, useEffect, useRef } from 'react';
import { Button, Table, Spin, Flex, Badge, Select, Space, Input, Typography, notification, Form } from 'antd';
import { useNavigate } from 'react-router';
// import Button from '../../../../../components/Button';
import axios from 'axios';
import {
  SearchOutlined
} from '@ant-design/icons';
import useDebouncedOnChange from '../../../../../functions/useDebouncedOnChange'
import datetimeParser from '../../../../../functions/datetimeParser';
import CaseStatus from '../../../components/caseStatus';
import SimpleAvatar from '../../../components/simpleAvatar';
import HighlightShort from '../../../../../components/HighlightShort';
import Highlight from '../../../../../components/HighlightShort';
import TitlePost from '../PostAsTitle';
import ContentPost from '../PostAsContent';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { UserContext } from '../../../components/privateroute';
import LogsAsContent from '../LogAsContent';
import { merge } from 'antd/es/theme/util/statistic';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import TextEditor from '../../../components/textEditor';
import Reminder from '../../../components/reminder';
import ReminderPost from '../PostAsReminder';
import CanWrapper from '../../../../../components/Can';
import SendNotifications from '../sendNotifications';
import AddStructure from '../addStructure';
import StructurePost from '../PostAsStructure';
const fileList = [];
const {Text} = Typography

const DataTable = (props) => {
  const [endOfRender, setEndOfRender] = useState(false);
  const {clear, setClear, saveLoading, setSaveLoading, fileList, setFileList, newPost, setNewPost, uploadedFiles, setUploadedFiles, areas, fields, posts, reload, setPosts, ticket, setTicket, setReload, scrollBox, logs, showLogs, setShowLogs, reminders, specialPeriods, postid, structures, setStructures} = props
  const globalUser = React.useContext(UserContext);
  const [credentials, setCredentials] = useState(ticket)
  const [order, setOrder] = useState(0)
  const [toggleEditor, switchToggleEditor] = useState(true)
  const [merge, setMerge] = useState([...posts, ...logs].sort((a, b) => a.dateline - b.dateline))
  const saveContents = (value) => {
    setNewPost(
      {
        ...newPost,
        contents: value
      }
    )  
  }

  const saveCredentialsPost = async (sendType = null, isused = null) => {
    if(uploadedFiles.length > 0 || newPost.contents != ''){
      // console.log(newPost.contents)
      setSaveLoading(true)
      const formData = new FormData()
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append(`files`, uploadedFiles[i]);
      }
      const newdateline = Math.floor(new Date().getTime() / 1000);
      const response = await axios.post("/sprawa/new/post",{
        credentials: {...newPost, dateline: newdateline},
        ticketid: ticket,
        sendtype: sendType
      });
      setTimeout(() => {
        setSaveLoading(false)
      }, 200)
      const postId = response.data.id
      const responseFiles = await axios.post("/sprawa/new/post/files",formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          TicketID: ticket.ticketid,
          PostID: postId
        }
      });
      setFileList([])
      setUploadedFiles([])
      setNewPost({})
      if(response.status == 200){
          notification.success({
              message: response.data.message,
              duration: 3
          });
          setClear(true)
      } else {
          notification.error({
              message: response.data.message,
              duration: 3
          });
      }
    }
    if(isused){
      setReload(!reload)
    }
}

  useEffect(() => {
    setNewPost({
      ticketid: ticket.ticketid,
      dateline: Math.floor(new Date().getTime() / 1000),
      userid: globalUser.user.mlg_users_id,
      fullname: globalUser.user.mlg_users_fullname,
      email: globalUser.contacts.find(contact => contact.type === 'E-mail')?.value,
      subject: ticket.subject,
      contents: '', // Initialize contents property
    });
  }, [posts, ticket]);

  useEffect(() => {
    if(posts && logs && reminders && structures){
      if(order == 0){
        setMerge([...posts, ...logs, ...reminders, ...structures].sort((a, b) => b.dateline - a.dateline));
      } else {
        setMerge([...posts, ...logs, ...reminders, ...structures].sort((a, b) => a.dateline - b.dateline));
      }
    }
  },[reload, posts, logs, reminders, structures, order])


  const customAction = (file) => {
    setUploadedFiles([...uploadedFiles, file]);
    setFileList([...fileList, file]);
    return new Promise((resolve) => {
      // Simulating a successful upload response after a delay
      setTimeout(() => {
        resolve({ success: true });
      }, 2000); // Simulating a delay of 2 seconds
    });
  };

  const scrollToPost = (postId) => {
    setTimeout(() => {
      const element = document.getElementById(`post${postId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500)
  };

  useEffect(() => {
      if (postid) { // Assuming `postid` is the ID of the post to scroll to
        scrollToPost(postid);
        document.getElementById(`post${postid}`).style.background = "#e5e5e5"
      }
    }, [endOfRender]);


      // Function to handle file drop
  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files).map(file =>
      Object.assign(file, {
        uid: Date.now() + Math.random() // ensure a unique key
      })
    );

    // Set the files in the existing upload component state
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);
  };

  // Function to handle drag over to prevent default behavior
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Update uploadedFiles state to reflect the current fileList
    // This assumes fileList contains the current state of files, including any additions or removals
    const updatedUploadedFiles = newFileList.map(file => file.originFileObj).filter(Boolean);
    setUploadedFiles(updatedUploadedFiles);
};
  
  const beforeUploadHandler = (file) => {
    // Perform your custom action here
    customAction(file).then((response) => {
    });
    // Returning false prevents the default upload behavior
    return false;
  };


  const saveCredentials = async (sendType = null) => {
      setSaveLoading(true)
      const response = await axios.post("/sprawa/save/values",{
          userid: globalUser.user.mlg_users_id,
          credentials: credentials,
          ticketid: ticket,
          areas: areas,
          fields: fields,
          sendtype: sendType
      });
      setTimeout(() => {
          setSaveLoading(false)
      }, 200)
      // setReload(!reload)
      if(response.status == 200){
          notification.success({
              message: response.data.message,
              duration: 3
          });
      } else {
          notification.success({
              message: response.data.message,
              duration: 3
          });
      }
  }

  return (
    <>
    <div className='mainpostcontent'>
    <div ref={scrollBox} style={{ overflowY: "auto", width: "100%", height: toggleEditor ? "calc(100% - 300px)" : "calc(100%)", padding: "0 20px"}}>
      <TitlePost data={ticket} order={order} setOrder={setOrder}></TitlePost>
      {posts && logs && reminders && structures ? merge.map((x) => {
        if(x.ticketauditlogid){
          return <LogsAsContent specialPeriods={specialPeriods} data={x} showLogs={showLogs}></LogsAsContent>
        } else if(x.sweventsid) {
          return <ReminderPost specialPeriods={specialPeriods} setReload={setReload} reload={setReload} showLogs={showLogs} data={x}></ReminderPost>
        } else if(x.idstructure) {
          return <StructurePost ticketid={ticket.ticketid} userid={globalUser.user.mlg_users_id} specialPeriods={specialPeriods} setReload={setReload} reload={setReload} showLogs={showLogs} data={x}></StructurePost>
        } else {
          return <ContentPost postid={postid} endOfRender={endOfRender} setEndOfRender={setEndOfRender} specialPeriods={specialPeriods} data={x}></ContentPost>
        }
      }) : <></>}
    </div>
    <CanWrapper perms={['can_add_posts']}> 
    <div className='editticket'
         onDrop={handleFileDrop}
         onDragOver={handleDragOver}
        style={{position:"relative", borderTop: "1px solid #00000038", height: toggleEditor ? "300px" : '0px'}}>
        <div className='openingEditing' style={toggleEditor ? {backgroundColor: 'white', color: 'black'} : {backgroundColor: '#B57531', color: 'white'} } onClick={() => switchToggleEditor(!toggleEditor)}>{toggleEditor ? 'Schowaj edytor' : 'Pokaż edytor'}</div>
        <div style={{
          display: toggleEditor ? "flex" : 'none',
          justifyContent: "flex-start",
          padding: "10px 20px",
          gap: "10px",
          flexWrap: 'wrap'
        }}>
          <Button type='primary'  onClick={() => {
              saveCredentialsPost(null, true)
          }}>Dodaj wpis</Button>
          <Upload
            multiple={true}
            action={customAction}
            beforeUpload={beforeUploadHandler}
            listType="picture"
            fileList={uploadedFiles}
            className="upload-list-inline"
            onChange={handleChange}
          >
        {/* <SendNotifications styleButton={{width: 'auto'}} saveCredentials={saveCredentials} saveLoading={saveLoading} setSaveLoading={setSaveLoading}></SendNotifications> */}
        <Button icon={<UploadOutlined />}>Wgraj nowy plik</Button>
        </Upload>
        <AddStructure setReload={setReload} reload={reload}></AddStructure>
        <Reminder reload={reload} setReload={setReload} ticketid={ticket.ticketid}></Reminder>
      </div>
      <div 
      style={{padding: "0px 20px", height: "50%"}}
      >
      <div style={{display: toggleEditor ? "block" : 'none' }}>
      <TextEditor setClear={setClear} clear={clear} value={newPost.contents} onChange={(e) => saveContents(e)}></TextEditor>
      </div>
      </div>
    </div>
    </CanWrapper>
    </div>
    </>
  );
};

export default DataTable;